import { gql } from "@apollo/client";

export const GET_CHARTDATA_BY_ADDRESS = gql`
  query GetChartDataByAddress($address: String!, $days: String!) {
    getChartDataByAddress(address: $address, days: $days) {
      prices
    }
  }
`;

export const GET_ZILLOW_DATA_BY_ADDRESS = gql`
  query GetZillowDataByAddress($address: String!) {
    getZillowDataByAddress(address: $address) {
      data
    }
  }
`;

export const LIST_DIGITALRE_BY_LIMIT = gql`
  query ListDigitalRealEstate($nextCursor: StartKeyInput!, $limit: Float) {
    listDigitalRealEstate(nextCursor: $nextCursor, limit: $limit) {
      data
    }
  }
`;

export const LIST_DIGITALRE_ALL = gql`
  query ListDigitalRealEstate {
    listDigitalRealEstate {
      data
    }
  }
`;

export const GET_DIGITAL_RE_COMPANY_BY_NAME = gql`
  query GetDigitalReCompany($companyName: String!) {
    getDigitalReCompany(companyName: $companyName) {
      __typename @skip(if: true)
      explorers
      reCompany
      reCompanyUrl
      socials {
        discord
        facebook
        gitbook
        github
        instagram
        reddit
        telegram
        twitter
      }
    }
  }
`;

export const GET_MARKET_CAPITALISATION = gql`
  query GetMarketCapitalisation {
    getMarketCapitalisation {
      data
    }
  }
`;
//getTraditionalInfo

export const GET_TRADITIONAL_INFO_BY_ID = gql`
  query GetTraditionalInfo($reId: String!, $companyName: String!) {
    getTraditionalInfo(reId: $reId, companyName: $companyName)
  }
`;

export const LIST_DIGITAL_DEBT = gql`
  query Query {
    listDigitalDebt
  }
`;

export const LIST_DIGITAL_BONDS = gql`
  query Query {
    listDigitalBonds
  }
`;
export const LIST_DIGITAL_ARTS = gql`
  query Query {
    listDigitalArts
  }
`;
export const GET_HISTORICAL_DATA_BY_ID = gql`
  query FetchHistoricalData($reId: String!, $companyName: String!) {
    fetchHistoricalData(reId: $reId, companyName: $companyName) {
      historicalData
    }
  }
`;


export const FETCH_HOTLISTINGS = gql`
  query FetchHotListings($qStr: String!){ 
    fetchHotListings(qStr: $qStr){ 
      data
    }
  }
`