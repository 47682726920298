import { useLazyQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  LIST_DIGITAL_ARTS,
  LIST_DIGITAL_BONDS,
  LIST_DIGITAL_DEBT,
} from "src/client/queries/analytics"
import {
  addDebtData,
  addBondsData,
  addArtsData,
} from "src/state/slices/reGridSlice"
import {
  formatArtsData,
  formatBondsData,
  formatDebtData,
} from "src/utils/gridutils"

export const useListDigitalToken = () => {
  const [debtData, setDebtData] = useState([])
  const [bondsData, setBondsData] = useState([])
  const [artsData, setArtsData] = useState([])
  const dispatch = useDispatch()

  const [listDigitalDebt, { loading: debtLoading, error: debtError }] =
    useLazyQuery(LIST_DIGITAL_DEBT, {
      onCompleted: (data: any) => {
        setDebtData(data.listDigitalDebt)
      },
    })

  const [listDigitalBonds, { loading: bondsLoading, error: bondsError }] =
    useLazyQuery(LIST_DIGITAL_BONDS, {
      onCompleted: (data: any) => {
        setBondsData(data.listDigitalBonds)
      },
    })
  const [listDigitalArts, { loading: artsLoading, error: artsError }] =
    useLazyQuery(LIST_DIGITAL_ARTS, {
      onCompleted: (data: any) => {
        setArtsData(data.listDigitalArts)
      },
    })



  useEffect(() => {
    if (bondsData.length > 0) {
      const formatedBondsData = formatBondsData(bondsData)
      dispatch(addBondsData(formatedBondsData))
    }
  }, [bondsData])

  useEffect(() => {
    if (debtData.length > 0) {
      const formatedDebtData = formatDebtData(debtData)
      dispatch(addDebtData(formatedDebtData))
    }
  }, [debtData])

  useEffect(() => {
    if (artsData.length > 0) {
      const formatedArtsData = formatArtsData(artsData)
      dispatch(addArtsData(formatedArtsData))
    }
  }, [artsData])

  return {
    debtLoading,
    bondsLoading,
    artsLoading,
    debtError,
    bondsError,
    artsError,
    listDigitalBonds,
    listDigitalDebt,
    listDigitalArts,
    bondsData,
    debtData,
    artsData,
  }
}
