import { ICellRendererParams } from "ag-grid-community"
import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useListDigitalToken } from "src/hooks/useListDigitalToken"
import ReGrid from "./ReGrid"
import { CustomFilter } from "src/filters/CountryFilter"
import { getCompanName } from "src/utils/gridutils"

const DebtDataTab = () => {
  const wide = true

  const debtGridRecords: any = useSelector((store: any) => store.reApiData.debt)
  const [uniqueCompanies, setUniqueCompanies] = useState([])
  const [uniqueDebtType, setUniqueDebtType] = useState([])
  const { listDigitalDebt } = useListDigitalToken()
  const checkValue = (params: ICellRendererParams) => {
    if (!params.value) return "NA"
    return params.value
  }
  const addPercentageSign = (params: ICellRendererParams) => {
    if (!params.value) return "NA"
    return params.value + "%"
  }

  const currencyFormatter = (params: ICellRendererParams) => {
    if (!params.value) return "NA"
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  
    return formatter.format(params.value)
  }

  useEffect(() => {
    if (debtGridRecords?.length === 0) return
    const { uniqueCompanies, uniqueDebtType } = debtGridRecords.reduce(
      (acc: any, obj: any) => {
        if (
          typeof obj["Company"] != "undefined" &&
          !acc.uniqueCompanies?.includes(obj["Company"])
        ) {
          acc.uniqueCompanies?.push(obj["Company"])
        }
        if (
          typeof obj["Debt Type"] != "undefined" &&
          !acc.uniqueDebtType?.includes(obj["Debt Type"])
        ) {
          acc.uniqueDebtType?.push(obj["Debt Type"])
        }

        return acc
      },
      { uniqueCompanies: [], uniqueDebtType: [] }
    )
    setUniqueCompanies(uniqueCompanies)
    setUniqueDebtType(uniqueDebtType)
  }, [debtGridRecords])

  useEffect(() => {
    if (debtGridRecords.length > 0) return

    listDigitalDebt()
  }, [])
  const columnDefs2 = [
    { field: "Debt Name", minWidth: 350, cellRenderer: checkValue },
    {
      field: "Debt Type",
      filter: CustomFilter,
      filterParams: { options: uniqueDebtType },
      cellRenderer: checkValue,
    },
    {
      field: "Est ROI",
      headerName: "Est. ROI",
      filter: "agNumberColumnFilter",
      cellRenderer: addPercentageSign,
    },
    {
      field: "Debt Tenure",
      cellRenderer: checkValue,
    },

    {
      field: "Debt Size",
      cellRenderer: currencyFormatter,
      filter: "agNumberColumnFilter",
    },
    {
      field: "Company",
      filter: CustomFilter,
      cellRenderer: "LinkCompanyCellRenderer",
      filterParams: {
        options: uniqueCompanies,
      },
    },
    { field: "Status", cellRenderer: checkValue },
    { field: "Token Name", cellRenderer: checkValue },
    { field: "Smart Contract", cellRenderer: checkValue },
    { field: "Fact Sheet", cellRenderer: "LinkCellFact" },

    { field: "Blockchain", cellRenderer: checkValue },
    {
      field: "managementFees",
      headerName: "Mgmt. Fees",
      cellRenderer: checkValue,
    },
    {
      field: "additionalFees",
      headerName: "Add. Fees",
      cellRenderer: checkValue,
    },
    { field: "Liquidity", cellRenderer: checkValue },
    { field: "Start Date", cellRenderer: checkValue },
    { field: "End Date", cellRenderer: checkValue },
  ]

  return (
    <ReGrid rowData={debtGridRecords} columnDefs={columnDefs2} wide={wide} />
  )
}

export default DebtDataTab
