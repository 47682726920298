export const pxData = {
  labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
};

export const pxDataWk = {
  labels: ["Feb 1", "Feb 8", "Feb 15", "Feb 23", "Mar 2"],
};
export const pxDataMt = {
  labels: ["Jan", "Mar", "Apr", "May", "Jun"],
};
export const pxDataYr = {
  labels: ["2016", "2017", "2018", "2019", "2020"],
};
export const chartOptions = {
  // layout: { padding: { right: 60 } },
  responsive: true,
  scales: {
    x: {
      title: {
        display: true,
      },
      ticks: {
        maxTicksLimit: 10,
      },
    },
    y: {
      title: {
        display: true,
      },
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: any, index: any, ticks: any) {
          return "$" + value;
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
      labels: {
        boxWidth: 0,
      },
    },
  },
};
