import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(stat: string, figure: string) {
  return { stat, figure };
}

const rows = [
  createData("24h", "0.0%"),
  createData("7d", "0.0%"),
  createData("30d", "2.3%"),
  createData("1y", "4.4%"),
];

export default function CardPriceStats() {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
      <Table
        sx={{
          minWidth: 250,
        }}
        aria-label="price stats"
      >
        <TableHead>
          <TableRow>
            {rows.map((row) => (
              <TableCell key={row.stat} align="right">
                {row.stat}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {rows.map((row) => (
              <TableCell
                key={row.stat}
                align="right"
                sx={{ fontWeight: "bold" }}
              >
                {row.figure}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
