import { useState, useEffect } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

function TwitterWidget({ companyName }: { companyName: string }) {
  const [twitterId, setTwitterId] = useState("");
  // function normalizeUsername(companyInput: string) {
  //   switch (companyInput) {
  //     case "3Blocks":
  //       return "3blocks_io";
  //     case "Akru":
  //       return "akru_co";
  //     case "Bonfire":
  //       return "BonfireDAO";
  //     case "Homebase":
  //       return "HomebaseDAO";
  //     case "Lofty":
  //       return "lofty_ai";
  //     case "RealT":
  //       return "RealTPlatform";
  //     case "Roofstock":
  //       return "Roofstock";
  //     case "Ryzer":
  //       return "Ryzer_app";
  //     default:
  //       return "3blocks_io";
  //   }
  // }
  useEffect(() => {
    setTwitterId(companyName);
  }, [companyName]);

  return (
    <div className="twitter-widget">
      {twitterId && twitterId.length > 0 && (
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName={twitterId}
          noHeader
          noFooter
          options={{ height: 300 }}
        />
      )}
    </div>
  );
}

export default TwitterWidget;
