import { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColumnApi, GridReadyEvent, GridApi } from "ag-grid-community";
import "../../assets/loader/GridLoader.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import BannerReAnalytics from '../ui/banner/BannerReAnalytics'

import { RiFilterLine } from "react-icons/ri";
import { RiFilterOffLine } from "react-icons/ri";
import { BsLayoutThreeColumns } from "react-icons/bs";
import { BiExport } from "react-icons/bi";
import {
  Button,
  IconButton,
  Popover,
  Switch,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCompany } from "../../state/company/companySlice";
import { BiLinkExternal } from "react-icons/bi";
import { sendEventToGA } from "src/utils/googleAnalytics/analytics";
import { getCompanName } from "src/utils/gridutils";

const ReGrid = ({ rowData, columnDefs, wide }: any) => {
  const isMobile = useMediaQuery("(max-width: 900px)");

  const [showFilters, setShowFilters] = useState(true);
  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      sortable: true,
      filter: showFilters,
      resizable: true,
      // floatingFilter: showFilters,
      // maxWidth: 200,
      // filterParams: {
      //   buttons: ['apply', 'clear'],
      // },
      cellClass: ["common_class"],
    };
  }, [showFilters]);

  const dispatch = useDispatch();

  const LinkCellRenderer = (params: any, onClick: (value: any) => void) => {
    if (!params.value) return "NA";

    return (
      <Link
        onClick={() => dispatch(updateCompany(params.data["Company"]))}
        to={`/analytics/${params.data.re_id}`}
        tabIndex={0}
      >
        {params.data["RE Address"]}
      </Link>
    );
  };
  const LinkCellFact = (params: any) => {
    if (!params.value) return "NA";
    return (
      <a
        className="companycell"
        href={params.data["Fact Sheet"]}
        rel="noopener noreferrer"
        target="_blank"
      >
        <BiLinkExternal />
        {params.data["Fact Sheet"]}
      </a>
    );
  };
  const LinkCellProduct = (params: any) => {
    return (
      <a
        className="companycell"
        href={params.data.product_page}
        rel="noopener noreferrer"
        target="_blank"
      >
        <BiLinkExternal />
        {params.data.product_page}
      </a>
    );
  };
  const LinkCompanyCellRenderer = (params: any) => {
    if (!params.value) return "NA";
    return (
      <div
        onClick={() =>
          sendEventToGA({
            category: "company links",
            action: "Click_ReCompanyLinks",
          })
        }
      >
        <a
          className="companycell"
          href={params.data.re_url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {params.data["Company"]}
          <BiLinkExternal />
        </a>
      </div>
    );
  };

  const [columnStates, setColumnStates] = useState(() =>
    columnDefs.map((column: any) => ({
      field: column.field,
      checked: true,
    }))
  );

  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const [gridApi, setGridApi] = useState<GridApi>();

  useEffect(() => {
    if (wide && gridApi && columnApi && gridRef.current && !isMobile) {
      columnApi.autoSizeAllColumns(); // resize columns to fit content
      // gridApi.sizeColumnsToFit() // resize columns to fit available grid width
    }

    if (!wide && gridApi && columnApi && gridRef.current && !isMobile) {
      // columnApi.autoSizeAllColumns() // resize columns to fit content
      gridApi.sizeColumnsToFit(); // resize columns to fit available grid width
    }

    // }
  }, [gridApi, columnApi, rowData, isMobile]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    if (rowData.length === 0) params.api.showLoadingOverlay();
  };
  const hideColumn = (columnName: string, isChecked: boolean) => {
    columnApi?.setColumnVisible(columnName, isChecked);
  };

  const handleRowClick = (e: any) => {
    // console.log("clicked", e.data["RE Company"])
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (field: string, isChecked: boolean) => {
    setColumnStates((prevColumnStates: any) =>
      prevColumnStates.map((columnState: any) =>
        columnState.field === field
          ? { ...columnState, checked: !columnState.checked }
          : columnState
      )
    );
    hideColumn(field, isChecked);
  };

  // useEffect(()=>{

  // },[columnStates.])
  const gridRef = useRef<AgGridReact>(null);

  //export to csv
  // const onBtnExport = useCallback(() => {
  //   gridRef.current!.api.exportDataAsCsv()
  // }, [])

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const gridOptions = {
    suppressMenuHide: true,
    suppressCellFocus: true,
    suppressDragLeaveHidesColumns: true,
  };
  const gridLoader =
    '<div class="loadingio-spinner-ripple-67ewwazwocp"><div class="ldio-dg66tpfuqso"><div></div><div></div></div></div>';
  return (
    <div className="regrid">
      <div className="regrid_options">
        {/* <Button
          color="primary"
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={onBtnExport}
          startIcon={<BiExport />}
          variant="contained"
        >
          Export All Data
        </Button> */}
        <Tooltip title="Show/Hide filters">
          <IconButton
            onClick={() => setShowFilters((prev) => !prev)}
            className="iconButton"
          >
            {showFilters ? (
              <RiFilterLine size={30} />
            ) : (
              <RiFilterOffLine size={30} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="Show/Hide Columns">
          <IconButton
            onClick={handleClick}
            aria-describedby={id}
            className="iconButton"
          >
            <BsLayoutThreeColumns size={24} />
          </IconButton>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="regrid_column_toggle">
            {columnStates.map((columnState: any) => (
              <div className="toggle" key={columnState.field}>
                <label>
                  <Switch
                    checked={columnState.checked}
                    onChange={(e) => {
                      handleChange(columnState.field, e.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span>{columnState.field}</span>
                </label>
              </div>
            ))}
          </div>
        </Popover>
      </div>
      {rowData.length > 0 ? (
        <div
          className="ag-theme-alpine"
          style={{ height: "85vh", width: "100%" }}
        >
          <AgGridReact
            tabIndex={0}
            rowHeight={70}
            ref={gridRef}
            onGridReady={onGridReady}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            onRowClicked={(e) => handleRowClick(e)}
            components={{
              LinkCellRenderer,
              LinkCompanyCellRenderer,
              LinkCellProduct,
              LinkCellFact,
            }}
            gridOptions={gridOptions}
            overlayLoadingTemplate={gridLoader}
            overlayNoRowsTemplate={
              '<span className="ag-overlay-loading-center">No data found to display.</span>'
            }
          ></AgGridReact>
        </div>
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ height: "85vh", width: "100%" }}
        >
          <AgGridReact
            tabIndex={0}
            rowHeight={40}
            ref={gridRef}
            onGridReady={onGridReady}
            rowData={["n/a"]}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            onRowClicked={(e) => handleRowClick(e)}
            components={{
              LinkCellRenderer,
              LinkCompanyCellRenderer,
              LinkCellProduct,
              LinkCellFact,
            }}
            gridOptions={gridOptions}
            overlayLoadingTemplate={gridLoader}
            overlayNoRowsTemplate={
              '<span className="ag-overlay-loading-center">No data found to display.</span>'
            }
          ></AgGridReact>
        </div>
      )}
    </div>
  );
};

export default ReGrid;
