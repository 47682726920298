import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

interface reGridDataState {
  records: any[]
  reData: any[]
  debt: any[]
  bonds: any[]
  arts: any[]
}

const initialState: reGridDataState = {
  records: [],
  reData: [],
  debt: [],
  bonds: [],
  arts: [],
}
const reGridSlice = createSlice({
  name: "reApiData",
  initialState,
  reducers: {
    addRecords: (state, action) => {
      state.records = [...state.records, ...action.payload]
    },
    addReData: (state, action) => {
      state.reData = [...state.reData, ...action.payload]
    },
    addDebtData: (state, action) => {
      state.debt = [...state.debt, ...action.payload]
    },
    addBondsData: (state, action) => {
      state.bonds = [...state.bonds, ...action.payload]
    },
    addArtsData: (state, action) => {
      state.arts = [...state.arts, ...action.payload]
    },
  },
})

export const selectCompany = (state: RootState) => state.company.companyName
export const { addRecords, addReData, addDebtData, addBondsData, addArtsData } =
  reGridSlice.actions
export default reGridSlice.reducer
