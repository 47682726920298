import { Breadcrumbs } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { calculateReadingTime, formatDate } from "src/utils/commonUtils";
import React, { useEffect } from "react";
import { useFetchBlogs } from "src/hooks/useFetchBlogs";

const renderList = (list: any) => {
  return list.map((item: any, index: any) => {
    if (item.text) return <li key={index}>{item.text}</li>;
    if (item.type === "list-item") {
      return renderList(item.children);
    } else if (item.type === "list-item-child") {
      return renderList(item.children);
    } else if (item.type === "paragraph" && item.children.length > 1) {
      return (
        <li key={index}>
          {item.children.map((innerItem: any) => (
            <span>{innerItem.text}</span>
          ))}
        </li>
      );
    } else if (item.type === "paragraph") {
      return <li key={index}>{item.children[0].text}</li>;
    }
    return null;
  });
};

const renderListUl = (list: any) => {
  return list.map((item: any, index: any) => {
    if (item.type === "list-item") {
      return renderListUl(item.children);
    } else if (item.type === "list-item-child") {
      return <li key={index}>{item.children[0].text}</li>;
    }
  });
};

const getContentFragment = (index: any, text: any, obj: any, type: any) => {
  let modifiedText = text;
  if (obj) {
    if (obj.bold) {
      modifiedText = <b key={index}>{text}</b>;
    }

    if (obj.italic) {
      modifiedText = <em key={index}>{text}</em>;
    }

    if (obj.underline) {
      modifiedText = <u key={index}>{text}</u>;
    }
    if (obj.type === "link") {
      modifiedText = (
        <a href={obj.href} target="_blank">
          {obj.children[0].text}
        </a>
      );
    }
  }

  switch (type) {
    case "heading-three":
      return (
        <h3 key={index}>
          {modifiedText.map((item: any, i: any) => (
            <React.Fragment key={i}>{item}</React.Fragment>
          ))}
        </h3>
      );
    case "paragraph":
      return (
        <p key={index}>
          {modifiedText.map((item: any, i: any) => (
            <React.Fragment key={i}>{item}</React.Fragment>
          ))}
        </p>
      );
    case "heading-four":
      return (
        <h4 key={index}>
          {modifiedText.map((item: any, i: any) => (
            <React.Fragment key={i}>{item}</React.Fragment>
          ))}
        </h4>
      );
    case "image":
      return (
        <img
          key={index}
          alt={obj.title}
          height={obj.height}
          width={obj.width}
          src={obj.src}
        />
      );
    case "numbered-list":
      return <ol>{renderList(obj.children)}</ol>;
    case "bulleted-list":
      return <ul>{renderListUl(obj.children)}</ul>;
    default:
      return modifiedText;
  }
};

export const Blog = () => {
  const params = useParams();
  const { fetchBlogs } = useFetchBlogs();
  const blogsData = useSelector((state: any) => state.blogsData.blogs);

  const blog = useSelector((state: any) =>
    state.blogsData.blogs.find((item: any) => item.slug === params.slug)
  );
  useEffect(() => {
    if (blogsData?.length > 0) return;
    fetchBlogs();
  }, []);

  return (
    <div className="blog">
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ marginBottom: "15px", fontSize: "16px" }}
        className="breadcrumb"
      >
        <Link to="/blogs" className="link">
          Blogs
        </Link>
        <span>{blog?.slug}</span>
      </Breadcrumbs>
      <h1>{blog?.title}</h1>
      <div className="info_div">
        <span className="timestamp">{formatDate(blog?.publishedAt)}</span>
        <span className="duration">
          {`${calculateReadingTime(blog?.content?.text)} min read`}
        </span>
      </div>
      <div className="img_div">
        <img src={blog?.coverImage.url} alt="" />
      </div>
      <div className="content-div">
        {blog?.content.raw.children.map((typeObj: any, index: any) => {
          const children = typeObj.children.map((item: any, itemindex: any) =>
            getContentFragment(itemindex, item.text, item, item.type)
          );
          return getContentFragment(index, children, typeObj, typeObj.type);
        })}
      </div>

      {/* <div
        className="content-div"
        dangerouslySetInnerHTML={{ __html: content.html }}
      /> */}
    </div>
  );
};
