import React from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import { Icon } from "leaflet";

const MapComponent = ({
  reAddress,
  coordinates,
}: {
  reAddress: string;
  coordinates: [number, number];
}) => {
  const customIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/684/684908.png",
    iconSize: [38, 38],
  });
  return (
    <MapContainer center={coordinates} zoom={13}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={coordinates} icon={customIcon}>
        <Popup>{reAddress}</Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;
