import { useState } from "react"
import Navbar from "../ui/nav/Navbar"
import Sidebar from "../ui/nav/Sidebar"
import { FaRobot } from "react-icons/fa"
import { Link, useLocation } from "react-router-dom"

export default function Header({ variant }: any): JSX.Element {
  const [showSidebar, setShowSidebar] = useState(false)

  const closeSidebar = () => {
    setShowSidebar(false)
  }

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar)
  }
  const location = useLocation()

  return (
    <>
      <Navbar
        variant={variant}
        toggleSidebar={toggleSidebar}
        showSidebar={showSidebar}
      />
      {/* {location.pathname === "/" && (
        <Link to="/chat">
          <div className="container-ai">
            <div className="askai">
              Search Tokenized Asset Data with Natural Language and GPT
              <span style={{ marginLeft: "10px", marginTop: "5px" }}>
                <FaRobot size={30} />
              </span>
            </div>
          </div>
        </Link>
      )} */}

      <Sidebar isOpen={showSidebar} closeSidebar={closeSidebar} />
    </>
  )
}
