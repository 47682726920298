import { Link, NavLink } from "react-router-dom";
import main_logo from "../../../assets/tka_header.png";
import { BiMenuAltRight } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import NewsletterForm from "../form/NewsletterForm";
import { useMediaQuery } from "@mui/material";

export default function Navbar({
  toggleSidebar,
  variant,
  showSidebar,
}: any): JSX.Element {
  const isMobile = useMediaQuery("(max-width: 700px)");

  return (
    <>
      <nav className={`navbar -${variant}`}>
        <div className="navbar__inner">
          <ul className="navbar__list">
            <Link className="navbar__logo" to="/">
              <img src={main_logo} alt="logo" />
            </Link>
          </ul>
          {!isMobile && (
            <ul className="navbar__list -main">
              <NewsletterForm variant="header" />
            </ul>
          )}
          {/* not needed now */}
          {/* <div className="navbar__button" onClick={toggleSidebar}>
            {!showSidebar ? (
              <BiMenuAltRight size={40} />
            ) : (
              <MdClose size={40} />
            )}
          </div> */}
        </div>
      </nav>
    </>
  );
}
