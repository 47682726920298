import { getIconForText } from "./icons/Icons";

const truncateStr = (str: string) =>
  str.replace(/ of daily trading volume$/, "");
export const formatGridData = (gridData: any) => {
  return gridData.map((item: any) => ({
    re_id: item.reId,
    "RE Address": item?.address,
    Company: item?.company,
    "Total Tokens": item?.totalTokens,
    "Est ROI": item?.estROI,
    Zestimate: !Array.isArray(item.zetimate) ? item.zestimate?.value : 0,
    "Platform Fees": item?.platFormFees,
    "Total Investments": item?.totalInverstment,
    "Asset Price": item?.assetPrice,
    Country: item?.country,
    State: item?.state,
    re_url: item?.url,
  }));
};

export const formatDebtData = (debtData: any) => {
  return debtData.map((item: any) => ({
    debt_id: item.debtId,
    "Debt Type": item?.debtType,
    "Debt Name": item?.debtName,
    "Debt Size": item?.debtSize,
    Status: item?.status,
    Company: item?.companyName,
    managementFees: item?.fundMgmtFees,
    additionalFees: item?.additionalFees,
    Blockchain: item?.blockchain,
    Liquidity: item?.liquidity,
    "Est ROI": item?.estRoi,
    "Debt Tenure": item?.debtTenure,
    "Token Name": item?.tokenName,
    "Smart Contract": item?.smartContract,
    "Fact Sheet": item?.factSheet,
    "Start Date": item?.startDate,
    "End Date": item?.endDate,
    re_url: item?.url,
  }));
};
export const formatBondsData = (bondsData: any) => {
  return bondsData.map((item: any) => ({
    "Asset Name": item?.assetName,
    Company: item?.companyName,
    managementFees: item?.fundMgmtFees,
    additionalFees: item?.additionalFees,
    "Est ROI": item?.estYieldToMaturity,
    "30 Day Yield": item?.day30SecYield,
    "Total Assets": item?.totalAssets,
    Liquidity: item?.liquidity,
    standardDeviation: item?.standardDeviation,
    "Token Name": item?.tokenName,
    "Smart Contract": item?.smartContract,
    "Fact Sheet": item?.factSheet,
    product_page: item?.productPage,
  }));
};

export const formatArtsData = (artsData: any) => {
  return artsData.map((item: any) => ({
    Artist: item?.artist,
    "Art Name": item?.artName,
    "Price per Share": item?.pricePerShare,
    "Smart Contract": item?.smartContract,
    Company: item?.companyName,
    Blockchain: item?.blockchain,
    re_url: item?.url,
  }));
};

export const getOtherInfoData = (reDataById: any, companyData: any) => {
  //companyName will be used later
  let socialsArr: any = [];
  for (let platform in companyData.socials) {
    if (companyData.socials[platform] !== null) {
      socialsArr.push({
        value: platform.charAt(0).toUpperCase() + platform.slice(1),
        url: companyData.socials[platform],
        icon: getIconForText(platform),
      });
    }
  }
  socialsArr.shift();
  return [
    {
      value: companyData.reCompany,
      url: companyData.reCompanyUrl,
    },
    {
      value: reDataById?.contractAddress,
    },
    {
      value: "Blockchain Explorer",
      url: companyData.explorers,
    },

    ...socialsArr,
  ];
};

export const getCompanName: any = {
  realt: "RealT",
  "3blocks": "3Blocks",
  lofty: "Lofty",
  roofstock: "Roofstock",
  akru: "AKRU",
  blueJay: "Bluejay",
  freeport: "Freeport",
  Ondo: "Ondo",
  centrifuge: "Centrifuge",
  "Cytus Finance": "Cytus Finance",
  "Mapple": "Mapple"

};

export const formatHistoricalGridData = (gridData: any) => {
  const formatedData = gridData.map((record: any) => ({
    Date: record?.timestamp,
    "Transfer From": record?.from,
    "Transfer To": record?.to,
    "Tokens Transferred": record?.data,
  }));
  return formatedData.reverse();
};
