import { useQuery } from "@apollo/client"
import React from "react"
import { GET_MARKET_CAPITALISATION } from "src/client/queries/analytics"

function MarketCapWidget(): any {
  const { data, loading, error } = useQuery(GET_MARKET_CAPITALISATION)

  if (loading) {
    return (
      <div className="stats -cap">
        <div className="indicator"></div>
        <div className="data">
          <div className="market">Market Capitalization:</div>
          <div className="price"> ...</div>
        </div>
      </div>
    )
  }
  return (
    <div className="stats -cap">
      <div className="indicator"></div>
      <div className="data">
        <div className="market">Market Capitalization:</div>
        <div className="price">{data?.getMarketCapitalisation?.data}</div>
      </div>
    </div>
  )
}

export default MarketCapWidget
