import { Avatar, Button, IconButton, Tab, Tabs } from "@mui/material";
import ReAnalyticsDetailLayout from "src/components/layouts/ReAnalyticsDetailLayout";
import { BiUpArrow } from "react-icons/bi";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { HistoricalGrid } from "src/components/sections/HistoricalGrid";
import TraditionalDataTab from "src/components/sections/TraditionalDataTab";
import PriceChart from "src/components/sections/Overview";
import Overview from "src/components/sections/Overview";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { BiLinkExternal } from "react-icons/bi";
import { BiCopy } from "react-icons/bi";
import { MdDone } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import TwitterWidget from "src/components/ui/widget/TwitterWidget";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useListDigitalRealEstate } from "src/hooks/useListDigitalRealEstate";
import { getCompanName, getOtherInfoData } from "src/utils/gridutils";
import useToggle from "src/hooks/useToggle";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaRobot } from "react-icons/fa";
import ChatBot from "../chat/ChatBot";

interface OtherInfoComponentProps {
  reDatabyId: any;
  reCompanyData: any;
}

const OtherInfoComponent: React.FC<OtherInfoComponentProps> = ({
  reDatabyId,
  reCompanyData,
}) => {
  const [copied, setCopied] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const otherInfoData = getOtherInfoData(reDatabyId, reCompanyData);
  //converting "info" object {key""value"} key value pairs into [key,value] to simplify the map method below
  let allValues: any = [];
  otherInfoData.flatMap((obj: any) => {
    if (obj?.value?.length > 0)
      allValues.push([
        Object.values(obj)[0],
        Object.values(obj)[1],
        Object.values(obj)[2],
      ]);
  });

  const handleCopyClick = (value: string) => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    const id = setTimeout(() => setCopied(false), 2000);
    setTimeoutId(id);
  };
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <div className="other__info">
      <h2>Info</h2>
      <div className="info_inner">
        {allValues.map((value: any, index: any) =>
          value[1] ? (
            <div className="value_div" key={index}>
              <span>
                <a href={value[1]} rel="noopener noreferrer" target="_blank">
                  {value[2] && value[2]}
                  {value[0]}
                  {value[1] && <BiLinkExternal />}
                </a>
              </span>
            </div>
          ) : (
            <div className="value_div" key={index}>
              <span>
                {`${value[0]?.slice(0, 8)}...${value[0]?.slice(-6)}`}
                {!copied ? (
                  <Tooltip title="Click to copy" placement="top-end">
                    <IconButton size="small">
                      <BiCopy
                        className="copyIcon"
                        onClick={() => handleCopyClick(value[0])}
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Copied to clipboard" placement="top-end">
                    <IconButton size="small">
                      <MdDone className="copyIcon" />
                    </IconButton>
                  </Tooltip>
                )}
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function ReAnalyticsDetails() {
  const [value, setValue] = useState(0);
  const [showai, toggle] = useToggle()
  const variant = 'pop'
  const { id } = useParams();

  const reData = useSelector((state: any) => state.reApiData.reData);
  const reDatabyId = useSelector((state: any) =>
    state.reApiData.reData.find((item: any) => item.reId === id)
  );
  const {
    reDataLoading,
    error,
    listDigitalRealEstate,
    getDigitalReCompany,
    reCompanyData,
  } = useListDigitalRealEstate();
  useEffect(() => {
    if (reData?.length > 0) return;
    listDigitalRealEstate({
      // variables: {
      //   nextCursor: {},
      //   limit: 10,
      // },
    });
  }, []);

  useEffect(() => {
    if (!reDatabyId) return;
    getDigitalReCompany({
      variables: {
        companyName: getCompanName[reDatabyId.company],
      },
    });
  }, [reDatabyId?.company]);

  // const companyName = useSelector(
  //   (state: RootState) => state.company.companyName
  // );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <ReAnalyticsDetailLayout>
        <div className="analyticsDetails__innerDiv">
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ marginBottom: "15px", fontSize: "16px" }}
            className="breadcrumb"
          >
            <Link to="/" className="link">
              Tokenized Real Estate
            </Link>
            <span>{reDatabyId?.address}</span>
          </Breadcrumbs>
          <div className="info__div">
            <div className="main__info">
              <div className="inner_wrapper">
                <div className="inner_wrapper_left">
                  <div className="title_div">
                    <Avatar sx={{ height: "60px", width: "60px" }}></Avatar>
                    <div className="title_inner">
                      <h2>{reDatabyId?.address}</h2>
                      <p>{getCompanName[reDatabyId?.company]}</p>
                    </div>
                  </div>
                  <div className="price_div">
                    <div className="price">
                      <h1>{reDatabyId?.tokenPrice}</h1>
                      <span>
                        <BiUpArrow />
                        0.23%
                      </span>
                    </div>
                    <div className="price_bar">
                      <BorderLinearProgress variant="determinate" value={50} />
                      <div className="values">
                        <div className="low">Low</div>
                        <div className="high">High</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner_wrapper_right">
                  <OtherInfoComponent
                    reDatabyId={reDatabyId}
                    reCompanyData={reCompanyData}
                  />
                </div>
                <div className="metrics_div">
                  {/* <div className="metric_detail">
                    <div className="key">Asset Price</div>
                    <div className="value">{reDatabyId?.assetPrice}</div>
                  </div>
                      {reDatabyId?.assetPrice
                        ? `$ ${reDatabyId?.assetPrice.toLocaleString()}`
                        : "NA"}
                    </div>
                  </div> */}
                  <div className="metric_detail">
                    <div className="key">Rent/Token</div>
                    <div className="value">$ 4.65/year</div>
                  </div>
                  {/* <div className="metric_detail">
                    <div className="key">Est ROI</div>
                    <div className="value">{reDatabyId?.estROI}</div>
                  </div>
                      {reDatabyId?.estROI > 0
                        ? `${reDatabyId?.estROI.toFixed(2)}%`
                        : "NA"}
                    </div>
                  </div> */}
                  <div className="metric_detail">
                    <div className="key">Total Tokens</div>
                    <div className="value">
                      {reDatabyId?.totalTokens
                        ? reDatabyId?.totalTokens.toLocaleString()
                        : "NA"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {reCompanyData && (
              <TwitterWidget
                companyName={reCompanyData?.socials?.twitter?.split("/")[3]}
              />
            )}
          </div>
          <div className="tabs_div">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                allowScrollButtonsMobile
              >
                <Tab
                  label="Traditional data"
                  {...a11yProps(0)}
                  tabIndex={0}
                  style={{ color: "rgba(255, 255, 255, 1)" }}
                />
                <Tab
                  label="Historical data"
                  {...a11yProps(1)}
                  tabIndex={0}
                  style={{ color: "rgba(255, 255, 255, 1)" }}
                />
                <Tab
                  label="Trading Chart"
                  {...a11yProps(2)}
                  tabIndex={0}
                  style={{ color: "rgba(255, 255, 255, 1)" }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {reDatabyId && (
                <TraditionalDataTab reId={id} reData={reDatabyId} />
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <HistoricalGrid reId={id} reData={reDatabyId} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Overview reData={reDatabyId} />
            </TabPanel>
          </div>
        </div>
        {/* {showai ? (
          <>
            <div className="form-overlay">
              <div className="form-container"><ChatBot variant={variant}/></div>
            </div>
            <div className="button-overlay">
              <div className="icon">
                <AiFillCloseCircle
                  color={"#041a25"}
                  size={40}
                  onClick={toggle}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="button-overlay">
              <div className="icon">
                <FaRobot color={"#041a25"} size={30} onClick={toggle} />
              </div>
            </div>
          </>
        )} */}
      </ReAnalyticsDetailLayout>
    </>
  );
}
