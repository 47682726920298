import Box from "@mui/material/Box"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { Button } from "@mui/material"

export const CustomFilter: any = forwardRef((props: any, ref: any): any => {
  const [arr, setArr] = useState<string[]>([])
  const [inputVal, setInputVal] = useState<string>("")
  const filterByInputValAndArr = (value: string) => {
    const inputValLength = inputVal.length
    const arrLength = arr.length
    if (inputValLength > 0 && arrLength > 0) {
      return (
        value.toLowerCase().includes(inputVal.toLowerCase()) &&
        arr.includes(value.toLowerCase())
      )
    }
    if (inputValLength > 0) {
      return value?.toLowerCase().includes(inputVal?.toLowerCase())
    }
    return arr.includes(value?.toLowerCase())
  }
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return arr.length > 0 || inputVal.length > 0
      },
      doesFilterPass(params: any) {
        const { column } = props
        const { data } = params
        switch (column.colId) {
          case "Country":
            return filterByInputValAndArr(data.Country)
          case "State":
            return filterByInputValAndArr(data.State)
          case "Company":
            return filterByInputValAndArr(data["Company"])
          case "Debt Type":
            return filterByInputValAndArr(data["Debt Type"])
          default:
            return false
        }
      },
      getModel() {
        return undefined
      },
      setModal() {},
    }
  })
  useEffect(() => {
    props.filterChangedCallback()
  }, [arr, inputVal])

  const handleChange = (event: any) => {
    const { name, checked } = event.target
    if (!checked) {
      setArr(arr.filter((item) => item !== name.toLowerCase()))
    } else {
      setArr([...arr, name.toLowerCase()])
    }
  }
  const handleReset = () => {
    setArr([])
    setInputVal("")
  }

  return (
    <Box sx={{ padding: "10px", display: "flex", flexDirection: "column" }}>
      <input
        onChange={(e) => setInputVal(e.target.value)}
        value={inputVal}
      ></input>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">{`Select ${props.column.colId}`}</FormLabel>
        <FormGroup>
          {props.options.map((option: string, idx: number) => (
            <FormControlLabel
            style={{textTransform: "capitalize"}}

              key={idx}
              control={
                <Checkbox
                  onChange={handleChange}
                  name={option}
                  checked={arr.includes(option?.toLowerCase())}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      </FormControl>
      <Button variant="outlined" onClick={handleReset}>
        Reset
      </Button>
    </Box>
  )
})
//v1
