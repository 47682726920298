import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import {
  FETCH_ALL_POSTS,
  FETCH_POSTS_BY_PREV_ID,
} from "src/client/queries/blogs";
import { hygraphClient } from "src/client";
import { addBlogs } from "src/state/slices/blogsSlice";

export const useFetchBlogs = () => {
  const [blogsData, setBlogsData] = useState<any>([]);
  const [newBlogsData, setNewBlogsData] = useState<any>([]);
  const dispatch = useDispatch();

  const [fetchBlogs, { loading }] = useLazyQuery(FETCH_ALL_POSTS, {
    client: hygraphClient,
    onCompleted: (data: any) => {
      setBlogsData(data.posts);
    },
  });

  const [fetchBlogsbyPrevId, { loading: nextLoading }] = useLazyQuery(
    FETCH_POSTS_BY_PREV_ID,
    {
      client: hygraphClient,
      onCompleted: (data: any) => {
        // setBlogsData(data.posts);
        setNewBlogsData(data.posts);
        // dispatch(addBlogs(data.posts));
      },
    }
  );

  useEffect(() => {
    if (blogsData.length > 0) {
      dispatch(addBlogs(blogsData));
    }
  }, [blogsData]);
  useEffect(() => {
    if (blogsData.length > 0) {
      dispatch(addBlogs(newBlogsData));
    }
  }, [newBlogsData]);

  return {
    fetchBlogs,
    blogsData,
    fetchBlogsbyPrevId,
    newBlogsData,
    loading,
    nextLoading,
  };
};
