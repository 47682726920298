import ReAnalyticsPageLayout from "src/components/layouts/ReAnalyticsPageLayout"
import ReGrid from "../../components/sections/ReGrid"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { useState, useEffect } from "react"
import BannerReAnalytics from "src/components/ui/banner/BannerReAnalytics"
import { CustomFilter } from "src/filters/CountryFilter"
import { ICellRendererParams } from "ag-grid-community"
import MarketCapWidget from "src/components/ui/widget/MarketCapWidget"
import { useSelector } from "react-redux"
import { useListDigitalRealEstate } from "src/hooks/useListDigitalRealEstate"
import DebtDataTab from "src/components/sections/DebtDataTab"
import ArtsDataTab from "src/components/sections/ArtsDataTab"
import NewsletterForm from "src/components/ui/form/NewsletterForm"
import { useMediaQuery } from "@mui/material"
import { FaRobot } from "react-icons/fa"
import useToggle from "src/hooks/useToggle"
import { AiFillCloseCircle } from "react-icons/ai"
import ChatBot from "../chat/ChatBot"
import CardHotlisting from "src/components/ui/card/CardHotlisting"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export const currencyFormatter = (params: ICellRendererParams) => {
  if (isNaN(params.value)) return "NA"
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })
  return formatter.format(params.value)
}
const addPercentageSign = (params: ICellRendererParams) => {
  if (isNaN(params.value)) return "NA"
  return params.value.toString() + "%"
}

const numberFormatter = (params: ICellRendererParams) => {
  if (!params.value) return "NA"
  return params.value.toLocaleString()
}
const checkValue = (params: ICellRendererParams) => {
  if (!params.value) return "NA"
  return params.value
}

export default function REAnalyticsPage() {
  const wide = false
  const isMobile = useMediaQuery("(max-width: 700px)")
  const [showai, toggle] = useToggle()
  const variant = "pop"

  const reGridRecords: any = useSelector(
    (store: any) => store.reApiData.records
  )

  const [uniqueCountries, setUniqueCountries] = useState([])
  const [uniqueState, setUniqueState] = useState([])
  const [uniqueCompanies, setUniqueCompanies] = useState([])

  const companyData = useSelector((state: any) => state.reApiData.companyData)

  const { reDataLoading, error, listDigitalRealEstate, getDigitalReCompany } =
    useListDigitalRealEstate()

  useEffect(() => {
    if (reGridRecords.length === 0) return
    const { uniqueCountries, uniqueState, uniqueCompanies } =
      reGridRecords.reduce(
        (acc: any, obj: any) => {
          if (
            typeof obj.Country != "undefined" &&
            !acc.uniqueCountries.includes(obj.Country)
          ) {
            acc.uniqueCountries.push(obj.Country)
          }
          if (
            typeof obj.State != "undefined" &&
            !acc.uniqueState.includes(obj.State)
          ) {
            acc.uniqueState.push(obj.State)
          }
          if (
            typeof obj["Company"] != "undefined" &&
            !acc.uniqueCompanies.includes(obj["Company"])
          ) {
            acc.uniqueCompanies.push(obj["Company"])
          }
          return acc
        },
        { uniqueCountries: [], uniqueState: [], uniqueCompanies: [] }
      )
    setUniqueCountries(uniqueCountries)
    setUniqueState(uniqueState)
    setUniqueCompanies(uniqueCompanies)
  }, [reGridRecords])
  useEffect(() => {
    if (reGridRecords.length > 0) return
    listDigitalRealEstate({
      // variables: {
      //   nextCursor: {},
      //   limit: 10,
      // },
    })
  }, [])

  const columnDefs = [
    {
      field: "RE Address",
      minWidth: 350,
      cellRenderer: "LinkCellRenderer",
      cellClass: ["common_class", "main_title"],
    },
    {
      field: "Company",
      filter: CustomFilter,
      cellRenderer: "LinkCompanyCellRenderer",
      // maxWidth: 140,
      filterParams: {
        options: uniqueCompanies,
      },
    },
    {
      field: "Total Tokens",
      filter: "agNumberColumnFilter",
      // maxWidth: 140,
      cellRenderer: numberFormatter,
    },
    // {
    //   field: "Est ROI",
    //   filter: "agNumberColumnFilter",
    //   maxWidth: 120,
    //   cellRenderer: addPercentageSign,
    // },
    // {
    //   field: "Last Traded Price",
    //   filter: "agNumberColumnFilter",
    //   // cellRenderer: currencyFormatter,
    // },
    {
      field: "Zestimate",
      filter: "agNumberColumnFilter",
      // maxWidth: 140,
      cellRenderer: currencyFormatter,
    },
    // {
    //   field: "Platform Fees",
    //   maxWidth: 140,
    //   filter: "agNumberColumnFilter",
    //   cellRenderer: currencyFormatter,
    // },
    // {
    //   field: "Total Investments",
    //   filter: "agNumberColumnFilter",
    //   maxWidth: 170,
    //   cellRenderer: currencyFormatter,
    // },
    // {
    //   field: "Asset Price",
    //   filter: "agNumberColumnFilter",
    //   maxWidth: 140,
    //   cellRenderer: currencyFormatter,
    // },
    {
      field: "Country",
      filter: CustomFilter,
      // maxWidth: 120,
      filterParams: { options: uniqueCountries },
    },
    {
      field: "State",
      filter: CustomFilter,
      // maxWidth: 120,
      filterParams: { options: uniqueState },
    },
  ]

  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <ReAnalyticsPageLayout>
        <div className="reAnalytics_inner">
          <BannerReAnalytics />
          {isMobile && (
            <div className="newsletterLayout">
              <NewsletterForm />
            </div>
          )}

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                variant="scrollable"
                onChange={handleChange}
                aria-label="basic tabs example"
                allowScrollButtonsMobile
              >
                <Tab
                  sx={{ padding: "10px" }}
                  label="Real Estate"
                  {...a11yProps(0)}
                  style={{ color: "rgba(255, 255, 255, 1)" }}
                />

                <Tab
                  sx={{ padding: "10px" }}
                  label="Debt"
                  {...a11yProps(1)}
                  style={{ color: "rgba(255, 255, 255, 1)" }}
                />

                {/* <Tab
                  sx={{ padding: "10px" }}
                  disabled
                  label="Art"
                  {...a11yProps(2)}
                  style={{ color: "rgba(255, 255, 255, 0.2)" }}
                /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="generalinfo">
                <div className="market">
                  <MarketCapWidget />
                  <CardHotlisting asset="realestate" />
                </div>
              </div>
              <ReGrid
                rowData={reGridRecords}
                columnDefs={columnDefs}
                wide={wide}
              />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <div className="generalinfo">
                <CardHotlisting asset="debt" />
              </div>
              <DebtDataTab />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <ArtsDataTab />
            </TabPanel>
          </Box>
        </div>
        {/* {showai ? (
          <>
            <div className="form-overlay">
              <div className="form-container"><ChatBot variant={variant}/></div>
            </div>
            <div className="button-overlay">
              <div className="icon">
                <AiFillCloseCircle
                  color={"#041a25"}
                  size={40}
                  onClick={toggle}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="button-overlay">
              <div className="icon">
                <FaRobot color={"#041a25"} size={30} onClick={toggle} />
              </div>
            </div>
          </>
        )} */}
      </ReAnalyticsPageLayout>
    </>
  )
}
