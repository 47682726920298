import { useMemo, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import ReactGA from "react-ga4";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ICellRendererParams, GridReadyEvent } from "ag-grid-community";
import { useListDigitalRealEstate } from "src/hooks/useListDigitalRealEstate";

const timestampFormatter = (params: ICellRendererParams) => {
  const date = new Date(params.value * 1000);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const HistoricalGrid = (props: any) => {
  const { reId, reData } = props;
  //google analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/analytics/historicalDataTab",
      title: "HistoricalDataTab",
    });
  }, []);

  //calling graphQl api
  const { getHistoricalData, historicalData, HistoricalDataLoading } =
    useListDigitalRealEstate();
  useEffect(() => {
    getHistoricalData({
      variables: { reId, companyName: reData.company },
    });
  }, []);

  const [rowData] = useState([
    {
      Date: "01-12-2022",
      "Market Cap": "51355",
      Price: "$45",
      Volume: "13000",
    },
    {
      Date: "12-11-2022",
      "Market Cap": "51355",
      Price: "$45",
      Volume: "13000",
    },
    {
      Date: "11-11-2022",
      "Market Cap": "51355",
      Price: "$45",
      Volume: "13000",
    },
    {
      Date: "01-11-2022",
      "Market Cap": "51355",
      Price: "$47",
      Volume: "13000",
    },
    {
      Date: "28-10-2022",
      "Market Cap": "51355",
      Price: "$45",
      Volume: "13000",
    },
    {
      Date: "24-10-2022",
      "Market Cap": "51355",
      Price: "$46",
      Volume: "13000",
    },
    {
      Date: "12-10-2022",
      "Market Cap": "51355",
      Price: "$45",
      Volume: "13000",
    },
  ]);
  const [columnDefs] = useState([
    { field: "Date", cellRenderer: timestampFormatter },
    {
      field: "Transfer From",
    },
    {
      field: "Transfer To",
    },
    {
      field: "Tokens Transferred",
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      filter: true,
      resizable: true,
      //   floatingFilter: true,

      // filterParams: {
      //   buttons: ['apply', 'clear'],
      // },
    };
  }, []);

  if (HistoricalDataLoading) {
    return (
      <div className="TraditionalTab_loader">
        <div className="loadingio-spinner-ripple-67ewwazwocp">
          <div className="ldio-dg66tpfuqso">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {historicalData.length > 0 ? (
        <div
          className="ag-theme-alpine"
          style={{ height: "60vh", width: "100%" }}
        >
          <AgGridReact
            rowData={historicalData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
          ></AgGridReact>
        </div>
      ) : (
        <div className="no_data">
          <h1>{reData?.address}</h1>
          <p>
            Sorry, no data is available at this time. We are actively working to
            bring it to you as soon as possible
          </p>
        </div>
      )}
    </div>
  );
};
