export default function BannerReAnalytics(): JSX.Element {
  return (
    <div className={`bannerReAnalytics`}>
      <h5>
        {`${"GLOBAL"} `}
        <span className="gradient-text">TOKENIZED ASSETS INTELLIGENCE </span>AT
        YOUR FINGERTIPS.
      </h5>
    </div>
  )
}
