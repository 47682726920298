import { ApolloClient, from, NormalizedCacheObject } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import cache from "./cache";

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      console.error(message);
    });
  }
});

const uploadAndHttpLink = createUploadLink({
  uri: process.env.REACT_APP_URI,
});

const hygraphLink = createUploadLink({
  uri: process.env.REACT_APP_HYGRAPH_ENDPOINT,
});

export let client: ApolloClient<NormalizedCacheObject>;

const apolloLink = from([errorLink, uploadAndHttpLink]);

client = new ApolloClient({ connectToDevTools: true, link: apolloLink, cache });

export default client;

export const hygraphClient: ApolloClient<NormalizedCacheObject> =
  new ApolloClient({
    connectToDevTools: true,
    link: from([errorLink, hygraphLink]),
    cache,
  });
