import { useState } from "react";
import { chartOptions } from "src/utils/data/price";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
// import { ThreeBlocksLoader } from '../loader/Loader'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const formatTimestamp = (value: string) => {
  var date = new Date(value); // Create a new Date object with the current date and time
  var options: any = { year: "numeric", month: "short", day: "numeric" }; // Define the format options
  var formattedDate = date.toLocaleDateString("en-US", options); // Format the date as a string
  return formattedDate; // Output: "16 Apr 2023"
};

export default function PriceChart({ priceData }: { priceData: any }) {
  const [loading, setLoading] = useState(true);
  const chartData = {
    labels: priceData.map((data: any) => formatTimestamp(data[0])),
    datasets: [
      {
        label: "Price in USD",
        data: priceData.map((data: any) => data[1]),
        fill: false,
        borderColor: "rgb(40, 159, 151)",
        tension: 0.1,
      },
    ],
  };

  return (
    <div className="pricechart">
      {/* {loading && <ThreeBlocksLoader />} */}
      <Line data={chartData} options={chartOptions} />
    </div>
  );
}
