import { gql } from "@apollo/client";

export const FETCH_ALL_POSTS = gql`
  query FetchAllPosts {
    posts(orderBy: publishedAt_DESC) {
      createdAt
      excerpt
      id
      publishedAt
      slug
      tags
      title
      updatedAt
      content {
        raw
        text
      }
      coverImage {
        url
      }
    }
  }
`;
// (orderBy: publishedAt_DESC)
export const FETCH_POSTS_BY_PREV_ID = gql`
  query FetchPosts($first: Int, $after: String) {
    posts(first: $first, after: $after, orderBy: publishedAt_DESC) {
      createdAt
      excerpt
      id
      publishedAt
      slug
      tags
      title
      updatedAt
      content {
        raw
        text
      }
      coverImage {
        url
      }
    }
  }
`;
