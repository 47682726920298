import { BiLinkExternal } from "react-icons/bi"
import ContactForm from "src/components/ui/form/ContactForm"
import connect from "src/assets/connect.svg"
import AboutMarquee, {
  PartnershipMarquee,
} from "src/components/ui/marquee/Marquee"
import { FaMapMarkerAlt } from "react-icons/fa"
import siddu from "../../assets/Siddu.jpg"
import tom from "../../assets/Tom.jpg"
import marc from "../../assets/Marc.jpg"
import ankit from "../../assets/Ankit.jpg"
import sam from "../../assets/Sam.jpg"
import pavan from "../../assets/pavan.jpeg"
import alex from "../../assets/alex.jpeg"

const About = () => {
  const timelineData = [
    {
      date: "Q1 2023",
      event:
        "Research on tokenized assets, tokenization companies. Build backend infra to support millions of data sets on AWS.",
      color: "#66d2e7c7",
    },
    {
      date: "Q2 2023",
      event: "Beta launch and Alpha launch.",
      color: "green",
    },
    {
      date: "Q3 2023",
      event: "Add AI models to learn Tokenized assets.",
      color: "purple",
    },
    {
      date: "Q4 2023",
      event:
        "Enable tokenization companies to directly list on the platform through APIs.",
      color: "#e5bd73",
    },
  ]
  const timelineDataTwo = [
    {
      date: "Q1 2024",
      event: "Expand AI models to respond to user queries on the platform.",
      color: "#2fc280",
    },
    {
      date: "Q2 2024",
      event: "Onboard tokenized assets data from private blockchains.",
      color: "#e5739b",
    },
    {
      date: "Q3 2024",
      event:
        "Introduce DeFi SDKs to tokenization companies through our partner protocols.",
      color: "#641064",
    },
    {
      date: "Q4 2024",
      event: "Build a DAO to develop compliant secondary trading platform.",
      color: "#cfcf63",
    },
  ]

  const teams = [
    {
      name: "Siddu Kadi",
      role: "Co-founder + Product Owner",
      info: "https://www.linkedin.com/in/siddu-kadi-96b031218/",
      img: siddu,
    },
    {
      name: "Tom Oommen",
      role: "Co-founder + AWS Architect",
      info: "https://www.linkedin.com/in/tom-oommen/",
      img: tom,
    },
    {
      name: "Marc Weiss",
      role: "Co-founder + Engineer (Smart Contracts)",
      info: "https://www.linkedin.com/in/marc-weisss/",
      img: marc,
    },
    {
      name: "Ankit Detroja",
      role: "Engineer (Backend)",
      info: "https://www.linkedin.com/in/ankit-patel-312515239/",
      img: ankit,
    },
    {
      name: "Mpere Annor",
      role: "Engineer (Frontend)",
      info: "https://www.linkedin.com/in/mpereannor/",
      img: sam,
    },
    {
      name: "Pavan Kumar",
      role: "Engineer (Fullstack)",
      info: "https://www.linkedin.com/in/pavan-kumar-955a9a193/",
      img: pavan,
    },
  ]
  const advisors = [
    {
      name: "Alex Hochberger",
      info: "https://www.linkedin.com/in/alexhochberger/",
      img: alex,
    },
  ]

  return (
    <div className="about">
      <div className="hero">
        <div className="overlay"></div>
        <div className="content">
          <div className="text">
            Introducing groundbreaking tokenized assets data platform.
          </div>
          <div className="subtext">
            With our comprehensive platform, users can seamlessly explore a
            diverse range of tokenized assets, including real estate, bonds,
            treasuries, commodities, debt, art, and more.
          </div>
        </div>
      </div>

      <div className="timeline">
        <h3 className="roadmap">Roadmap</h3>

        <div className="checkpoints">
          <div className="point">
            {timelineData.map((item) => (
              <>
                <div className="card">
                  <div className="quarter">
                    <FaMapMarkerAlt
                      size={24}
                      style={{
                        marginRight: "5px",
                        color: `${item.color}`,
                      }}
                    />
                    {item.date}
                  </div>
                  <div>{item.event}</div>
                </div>
                <div className="connect">
                  <img src={connect} alt="connect" />
                </div>
              </>
            ))}
          </div>
          <div className="point">
            {timelineDataTwo.map((item) => (
              <>
                <div className="card">
                  <div className="quarter">
                    <FaMapMarkerAlt
                      size={24}
                      style={{ marginRight: "5px", color: `${item.color}` }}
                    />
                    {item.date}
                  </div>
                  <div>{item.event}</div>
                </div>
                <div className="connect">
                  <img src={connect} alt="connect" />
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      <div className="vision">
        <div className="content">
          <div className="text">Vision</div>
          <div className="subtext">
            Empowering investors with AI-driven insights for tokenized assets,
            revolutionizing the way they navigate and capitalize on digital
            opportunities.
          </div>
        </div>
        <div className="content">
          <div className="text">Mission</div>
          <div className="subtext">
            Our mission is to provide comprehensive AI-driven data and analytics
            for tokenized assets, democratizing access and enabling informed
            investment decisions.
          </div>
        </div>
      </div>
      <div className="testimonials">
        <h3 className="roadmap">Testimonials</h3>
        <AboutMarquee />
      </div>
      <div className="partnerships">
        <div className="text">Partnerships</div>
        <PartnershipMarquee />
      </div>

      <div className="team">
        <div className="partnerships -team">
          <div className="text">Team</div>
          <div className="subtext">
            Our team is made up of experts in finance, data analysis, and
            technology. We have backgrounds in some of the world's leading
            financial institutions and are committed to providing our clients
            with the best possible service and insights.
          </div>
        </div>
        <div className="card-grid -team">
          {teams.map((team: any) => (
            <div key={team.name} className="card">
              <div className="content -team">
                <div className="company -team">
                  <div className="avatar">
                    <img src={team.img} alt={team.name} />
                  </div>
                  <div>{team.name}</div>
                  <div>
                    {team.info.length > 0 ? (
                      <a
                        href={team.info}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <BiLinkExternal />
                      </a>
                    ) : null}
                  </div>
                </div>
                <div className="description">{team.role}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="partnerships">
        <div className="text">Advisors</div>
        <div className="subtext">
          We have an advisory board composed of experts in finance, economics,
          and technology. Our advisors provide us with guidance and insights to
          help us better serve our clients.
        </div>
      </div>
      <div className="card-grid">
        {advisors.map((advisor: any) => (
          <div key={advisor.name} className="card">
            <div className="content">
              <div className="company -team">
                <div className="avatar">
                  <img src={advisor.img} alt={advisor.name} />
                </div>
                <div>{advisor.name}</div>
                <div>
                  {advisor.info.length > 0 ? (
                    <a
                      href={advisor.info}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <BiLinkExternal />
                    </a>
                  ) : null}
                </div>
              </div>
              <div className="description">{advisor.role}</div>
            </div>
          </div>
        ))}
      </div>
      <ContactForm />
    </div>
  )
}

export default About
