import trending from "../../../assets/trending.png"
import { useQuery } from "@apollo/client"

import { FETCH_HOTLISTINGS } from "src/client/queries/analytics"
import { Skeleton } from "@mui/material"
import { BiLinkExternal } from "react-icons/bi"
const addPercentageSign = (value: any) => {
  if (!value) return "NA"
  return value + "%"
}

function CardHotlisting({ asset }: { asset: string }) {
  const { data, loading } = useQuery(FETCH_HOTLISTINGS, {
    variables: { qStr: asset },
  })

  return (
    <div className="listing">
      <div className="heading">
        <div className="label">
          <img src={trending} alt="trending" /> <span>Hot Listing </span>
        </div>
      </div>
      <div className="field -header">
        {asset === "realestate" ? (
          <div className="entry -one">Address</div>
        ) : asset === "debt" ? (
          <div className="entry -one">Debt Name</div>
        ) : null}
        <div className="entry -two">Company</div>
        {asset === "realestate" ? (
          <div className="entry -three"># Tokens</div>
        ) : asset === "debt" ? (
          <div className="entry -three">ROI</div>
        ) : null}
      </div>

      {loading && (
        <>
          <Skeleton height={"30px"} variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton height={"30px"} variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton height={"30px"} variant="text" sx={{ fontSize: "1rem" }} />
        </>
      )}

      {data?.fetchHotListings.data.map((info: any) => (
        <div key={info.estRoi} className="field">
          {asset === "realestate" && (
            <>
              <div className="entry -one">{info.address}</div>
              <div className="entry -two">
                <a
                  className="companycell"
                  href={info.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {info.company}
                  <BiLinkExternal />
                </a>
              </div>
              <div className="entry -three">{info.totalTokens}</div>
            </>
          )}
          {asset === "debt" && (
            <>
              <div className="entry -one">{info.debtName}</div>
              <div className="entry -two">
                <a
                  className="companycell"
                  href={info.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {info.companyName}
                  <BiLinkExternal />
                </a>
              </div>
              <div className="entry -three">
                {addPercentageSign(info.estRoi)}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default CardHotlisting
