import React, { useEffect, useState } from "react";
import ReGrid from "./ReGrid";
import { useListDigitalToken } from "src/hooks/useListDigitalToken";
import { useSelector } from "react-redux";
import { ICellRendererParams } from "ag-grid-community";
import { CustomFilter } from "src/filters/CountryFilter";

const ArtsDataTab = () => {
  const wide = false;

  const artsGridRecords: any = useSelector(
    (store: any) => store.reApiData.arts
  );
  const [uniqueCompanies, setUniqueCompanies] = useState([]);
  const { listDigitalArts } = useListDigitalToken();

  useEffect(() => {
    if (artsGridRecords?.length === 0) return;
    const { uniqueCompanies } = artsGridRecords.reduce(
      (acc: any, obj: any) => {
        if (
          typeof obj["Company"] != "undefined" &&
          !acc.uniqueCompanies?.includes(obj["Company"])
        ) {
          acc.uniqueCompanies?.push(obj["Company"]);
        }
        return acc;
      },
      { uniqueCompanies: [] }
    );
    setUniqueCompanies(uniqueCompanies);
  }, [artsGridRecords]);

  useEffect(() => {
    if (artsGridRecords.length > 0) return;

    listDigitalArts();
  }, []);
  const checkValue = (params: ICellRendererParams) => {
    if (!params.value) return "NA";
    return params.value;
  };

  const columnDefs6 = [
    { field: "Artist" },
    { field: "Art Name" },
    { field: "Price per Share" },
    { field: "Smart Contract", cellRenderer: checkValue },
    {
      field: "Company",
      filter: CustomFilter,
      filterParams: { options: uniqueCompanies },
      cellRenderer: "LinkCompanyCellRenderer",
    },
    {
      field: "Blockchain",
      cellRenderer: checkValue,
    },
  ];

  return (
    <ReGrid rowData={artsGridRecords} columnDefs={columnDefs6} wide={wide} />
  );
};

export default ArtsDataTab;
