import { createSlice } from "@reduxjs/toolkit";

interface blogsDataState {
  blogs: any[];
}

const initialState: blogsDataState = {
  blogs: [],
};

const blogsSlice = createSlice({
  name: "blogsData",
  initialState,
  reducers: {
    addBlogs: (state, action) => {
      state.blogs = [...state.blogs, ...action.payload];
    },
  },
});

export const { addBlogs } = blogsSlice.actions;
export default blogsSlice.reducer;
