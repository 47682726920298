import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GET_DIGITAL_RE_COMPANY_BY_NAME,
  GET_HISTORICAL_DATA_BY_ID,
  GET_TRADITIONAL_INFO_BY_ID,
  LIST_DIGITALRE_ALL,
  LIST_DIGITALRE_BY_LIMIT,
} from "src/client/queries/analytics";
import { formatGridData, formatHistoricalGridData } from "src/utils/gridutils";
import { useDispatch } from "react-redux";
import { addReData, addRecords } from "src/state/slices/reGridSlice";

export const useListDigitalRealEstate = () => {
  const [gridData, setGridData] = useState([]);
  const [reCompanyData, setReCompanyData] = useState<any>({});
  const [traditionalData, setTraditionalData] = useState<any>({});
  const [historicalData, setHistoricalData] = useState<any>([]);
  const dispatch = useDispatch();

  const [listDigitalRealEstate, { loading: reDataLoading, error }] =
    useLazyQuery(LIST_DIGITALRE_ALL, {
      onCompleted: (data: any) => {
        setGridData(data.listDigitalRealEstate.data.zillow.res);
      },
    });
  // const [listDigitalRealEstate, { loading, error }] = useLazyQuery(
  //   LIST_DIGITALRE_BY_LIMIT,
  //   {
  //     onCompleted: (data: any) => {
  //       setGridData(data.listDigitalRealEstate.data.zillow);
  //     },
  //   }
  // );

  const [
    getDigitalReCompany,
    { loading: companyLoading, error: companyError },
  ] = useLazyQuery(GET_DIGITAL_RE_COMPANY_BY_NAME, {
    onCompleted: (data: any) => {
      setReCompanyData(data.getDigitalReCompany);
    },
  });

  const [getTraditionalInfo, { loading: traditionalDataLoading }] =
    useLazyQuery(GET_TRADITIONAL_INFO_BY_ID, {
      onCompleted: (data: any) => {
        setTraditionalData(data?.getTraditionalInfo);
      },
    });

  const [getHistoricalData, { loading: HistoricalDataLoading }] = useLazyQuery(
    GET_HISTORICAL_DATA_BY_ID,
    {
      onCompleted: (data: any) => {
        const formattedData = formatHistoricalGridData(
          data?.fetchHistoricalData?.historicalData
        );
        setHistoricalData(formattedData);
      },
    }
  );

  useEffect(() => {
    if (gridData.length > 0) {
      const formatedData = formatGridData(gridData);
      dispatch(addRecords(formatedData));
      dispatch(addReData(gridData));
    }
  }, [gridData]);

  return {
    reDataLoading,
    error,
    listDigitalRealEstate,
    getDigitalReCompany,
    reCompanyData,
    getTraditionalInfo,
    traditionalData,
    getHistoricalData,
    HistoricalDataLoading,
    historicalData,
    traditionalDataLoading,
    companyLoading,
  };
};
