import { BlogPostCard } from "src/components/ui/blogPost/BlogPostCard";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFetchBlogs } from "src/hooks/useFetchBlogs";
import { formatDate } from "src/utils/commonUtils";
import { sendEventToGA } from "src/utils/googleAnalytics/analytics";
import { useSelector } from "react-redux";

export const BlogsList = () => {
  const { fetchBlogs, fetchBlogsbyPrevId, newBlogsData, loading, nextLoading } =
    useFetchBlogs();

  const allBlogs: any[] = useSelector((store: any) => store.blogsData.blogs);
  const [latestBlog, ...prevBlogs] = allBlogs;

  const [page, setPage] = useState<number>(0);

  const FETCH_LIMIT = 10;

  //todo optimize -> combine 2 useEffects
  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    const lastBlogId = allBlogs[allBlogs.length - 1]?.id;
    if (lastBlogId?.length > 0) {
      fetchBlogsbyPrevId({
        variables: {
          first: FETCH_LIMIT,
          after: lastBlogId,
          orderBy: "publishedAt_DESC",
        },
      });
    }
  }, [page]);

  //google analytics
  const handleGA = (blogTitle: string) => {
    sendEventToGA({
      category: `blog-${blogTitle}`,
      action: "blog_click",
    });
  };

  const intObserver: React.MutableRefObject<IntersectionObserver | undefined> =
    useRef<IntersectionObserver>();
  const lastPostRef = useCallback(
    (post) => {
      if (loading) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting) {
          setPage((prev: number) => prev + 1);
        }
      });
      if (post) intObserver?.current?.observe(post);
    },
    [loading, newBlogsData, fetchBlogsbyPrevId]
  );

  const blogList = prevBlogs.map((blog: any, i: number) => {
    if (prevBlogs.length === i + 1) {
      return <BlogPostCard ref={lastPostRef} blog={blog} key={i} />;
    }
    return <BlogPostCard blog={blog} key={i} />;
  });

  return (
    <div className="blog_page">
      <h1>Blogs</h1>
      {loading ? (
        <div className="loader_div">
          <div className="loadingio-spinner-ripple-67ewwazwocp">
            <div className="ldio-dg66tpfuqso">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <Link to={`/blogs/${latestBlog?.slug}`}>
            <div
              className="main_blog"
              onClick={() => handleGA(latestBlog?.title)}
            >
              <div className="left_box">
                <img src={latestBlog?.coverImage?.url} alt="blog" />
              </div>
              <div className="right_box">
                <div className="tags">
                  {latestBlog?.tags.map((tag: string, i: number) => (
                    <span key={i}>{tag}</span>
                  ))}
                </div>
                <h3>
                  {latestBlog?.title.length > 60
                    ? `${latestBlog?.title.slice(0, 60)}...`
                    : latestBlog?.title}
                </h3>
                <p className="mobile">
                  {latestBlog?.excerpt.length > 140
                    ? `${latestBlog?.excerpt.slice(0, 140)}...`
                    : latestBlog?.excerpt}
                </p>
                <p className="desktop">
                  {latestBlog?.excerpt.length > 140
                    ? `${latestBlog?.excerpt.slice(0, 200)}...`
                    : latestBlog?.excerpt}
                </p>
                <div className="content_footer">
                  <span className="timestamp">
                    {formatDate(latestBlog?.publishedAt)}
                  </span>
                  <span className="link">Read Post</span>
                </div>
              </div>
            </div>
          </Link>
          <div className="blogs_list">{blogList}</div>
          {nextLoading && (
            <div className="loader_div">
              <div className="loadingio-spinner-ripple-67ewwazwocp">
                <div className="ldio-dg66tpfuqso">
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
