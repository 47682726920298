import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "src/utils/commonUtils";
import { sendEventToGA } from "src/utils/googleAnalytics/analytics";

export const BlogPostCard = forwardRef(({ blog }: any, ref: any) => {
  const { title, excerpt, publishedAt, slug, coverImage, tags } = blog;

  //google analytics
  const handleGA = (blogTitle: string) => {
    sendEventToGA({
      category: `blog-${blogTitle}`,
      action: "blog_click",
    });
  };

  const blogPostBody = (
    <div className="blog_card" onClick={() => handleGA(title)}>
      <div className="img_div">
        <img src={coverImage?.url} alt="" />
      </div>
      <div className="content_div">
        <div className="content_div_inner">
          <div className="tags">
            {tags.map((tag: string, i: number) => (
              <span key={i}>{tag}</span>
            ))}
          </div>
          <h3>{`${title.slice(0, 50)}...`}</h3>
          <p>{`${excerpt.slice(0, 130)}...`}</p>
          <div className="content_footer">
            <span className="timestamp">{formatDate(publishedAt)}</span>
            <span className="link">Read Post</span>
          </div>
        </div>
      </div>
    </div>
  );

  return ref ? (
    <Link to={`/blogs/${slug}`} ref={ref}>
      {blogPostBody}
    </Link>
  ) : (
    <Link to={`/blogs/${slug}`}>{blogPostBody}</Link>
  );
});
