import { useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Button from "../button/Button"
import Airtable from "airtable"
import { toast } from "react-toastify"
import { MdOutlineUnsubscribe } from "react-icons/md"

// todo move to env
const AirtableApiKey =
  "patXhef48ma071Fn1.9d2ff80d704303f7a257f38ce3eddbd4c95c734fd9c141305ee137fd39f3c924"
const AirtableBaseId = "appBc3pWp0QGbz65K"
const AirtableTableName = "News_Letter"

interface INewsletterInterface {
  variant?: string
}
interface INewsletterFormInputs {
  email: string
}

const newsletterFormValues = {
  email: "",
}
const newsletterFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please check the email format")
    .required("Please enter your email"),
})

export default function NewsletterForm({
  variant,
}: INewsletterInterface): JSX.Element {
  const [loading, setLoading] = useState(false)

  const base = new Airtable({ apiKey: AirtableApiKey }).base(AirtableBaseId)

  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors, isSubmitting, isSubmitted },
  } = useForm<INewsletterFormInputs>({
    defaultValues: newsletterFormValues,
    resolver: yupResolver(newsletterFormSchema),
    mode: "onBlur",
  })

  const checkForDuplicate = async (valueToCheck: any) => {
    const records = await base(AirtableTableName)
      .select({
        filterByFormula: `{Email} = '${valueToCheck}'`,
      })
      .all()
    return records.length > 0
  }

  const onSubmit: SubmitHandler<INewsletterFormInputs> = async (data: any) => {
    setLoading(true)
    const check = await checkForDuplicate(data.email)

    if (check) {
      setLoading(false)
      reset()
      toast.warn("Email Already Exists!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
      return
    }
    try {
      const record = await base(AirtableTableName).create({
        Email: data.email,
      })

      if (record.getId().length > 0) {
        setLoading(false)
        reset()
        toast.success("Email added successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }
    } catch (error) {
      setLoading(false)
      reset()
    }
  }

  const cls = `newsletter -${variant}`

  return (
    <div className={cls}>
      <div className="subscribe">
        <div className="ctaText">
          Subscribe to our Tokenized World Newsletter!
        </div>
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="input">
          <div>
            {variant === "header" ? (
              <input
                type="email"
                placeholder="Subscribe to Tokenized Assets newsletter."
                {...register("email", {})}
              />
            ) : (
              <input
                type="email"
                placeholder="Subscribe to Tokenized Assets newsletter."
                {...register("email", {})}
              />
            )}
          </div>
        </div>
        <Button
          icon={
            <MdOutlineUnsubscribe
              style={{
                marginTop: "2px",
              }}
              size={20}
            />
          }
          variant="newsletter"
          label=""
          loading={loading}
        />
      </form>
    </div>
  )
}
