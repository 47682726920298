import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/sections/Footer";
import Header from "./components/sections/Header";
import { ReAnalyticsDetails } from "./pages/reAnalytics/ReAnalyticsDetail";
import REAnalyticsPage from "./pages/reAnalytics/ReAnalyticsPage";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TermsOfUse } from "./pages/other/TermsOfUse";
import { PrivacyPolicy } from "./pages/other/PrivacyPolicy";
import { CookiePolicy } from "./pages/other/CookiePolicy";
import About from "./pages/other/About";
import { BlogsList } from "./pages/blogPosts/BlogsList";
import { Blog } from "./pages/blogPosts/Blog";
import CardHotlisting from "./components/ui/card/CardHotlisting";
const MEASUREMENT_ID = process.env.REACT_APP_G_ANALYTICS;

function App() {
  const location = useLocation();

  //google analytics
  useEffect(() => {
    if (MEASUREMENT_ID !== undefined) {
      ReactGA.initialize(MEASUREMENT_ID);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      ReactGA.send({ hitType: "pageview", page: "/home", title: "HomePage" });
    } else if (location.pathname.includes("analytics")) {
      ReactGA.send({
        hitType: "pageview",
        page: "/analytics",
        title: "AnalyticsDetailsPage",
      });
    } else if (location.pathname.includes("blogs")) {
      ReactGA.send({
        hitType: "pageview",
        page: "/blogs",
        title: "blogsPage",
      });
    }
  }, [location.pathname]);

  return (
    <>
      <Header variant="standard" />
      <div className="main__wrapper">
        <Routes>
          <Route path="/" element={<REAnalyticsPage />} />
          <Route path="/analytics/:id" element={<ReAnalyticsDetails />} />
          <Route path="/terms_of_use" element={<TermsOfUse />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/cookie_policy" element={<CookiePolicy />} />
          <Route path="/about" element={<About />} />
          <Route path="/blogs" element={<BlogsList />} />
          <Route path="/blogs/:slug" element={<Blog />} />
        </Routes>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
