import { useEffect } from "react";
import ReactGA from "react-ga4";
import {
  Box,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import PriceChart from "../ui/chart/PriceChart";
import { useState } from "react";
import CardTokenStats from "../ui/card/CardTokenStats";
import CardPriceStats from "../ui/card/CardPriceStats";
import { useLazyQuery } from "@apollo/client";
import { GET_CHARTDATA_BY_ADDRESS } from "src/client/queries/analytics";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//function to convert address into coin gecko api key pattern
const convertToApiId = (address: string) => {
  let words = address?.replace(/,/g, "").split(" ");
  let apiId = `${words[0]}-${words[1]}-${words[2]}`;
  return apiId.toLowerCase();
};

export default function Overview({ reData }: { reData: any }) {
  const [value, setValue] = useState(0);
  const [timeline, updateTimeline] = useState("7");
  const [alignment, setAlignment] = useState("Price");

  //google analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/analytics/tradingChartTab",
      title: "TradingChartTab",
    });
  }, []);

  const [getDays, setGetDays] = useState("7");
  const [priceData, setPriceData] = useState([]);

  const [getChartDataByAddress, { loading }] = useLazyQuery(
    GET_CHARTDATA_BY_ADDRESS,
    {
      onCompleted: (data: any) => {
        setPriceData(data.getChartDataByAddress.prices);
      },
    }
  );

  useEffect(() => {
    getChartDataByAddress({
      variables: {
        address: convertToApiId(reData?.address),
        days: getDays,
      },
    });
  }, []);

  useEffect(() => {
    getChartDataByAddress({
      variables: {
        address: convertToApiId(reData?.address),
        days: getDays,
      },
    });
  }, [getDays]);

  const handleChangeToggle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };
  const handleChangeTimeline = (
    event: React.SyntheticEvent,
    newTimeline: string
  ) => {
    updateTimeline(newTimeline);
    setGetDays(newTimeline);
  };

  if (loading) {
    return (
      <div className="TraditionalTab_loader">
        <div className="loadingio-spinner-ripple-67ewwazwocp">
          <div className="ldio-dg66tpfuqso">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {priceData.length > 0 ? (
        <div className="overview">
          <div className="viewoptions">
            <h2 className="title">{reData?.address}</h2>
            <small>Last Updated Date</small>
            <div>
              <div className="togglers">
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChangeToggle}
                  aria-label="Platform"
                >
                  <ToggleButton value="Price">Price</ToggleButton>
                  <ToggleButton value="Market Cap">Market Cap</ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                  className="timeline"
                  color="primary"
                  value={timeline}
                  exclusive
                  onChange={handleChangeTimeline}
                  aria-label="Platform"
                >
                  <ToggleButton value="7">1W</ToggleButton>
                  <ToggleButton value="14">2W</ToggleButton>
                  <ToggleButton value="30">1M</ToggleButton>
                  <ToggleButton value="90">3M</ToggleButton>
                  <ToggleButton value="max">MAX</ToggleButton>
                </ToggleButtonGroup>
              </div>
              <TabPanel value={value} index={0}>
                <PriceChart priceData={priceData} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                {/* <PriceChart timeline={timeline} /> */}
              </TabPanel>
            </div>
            <CardPriceStats />
          </div>
          <div className="stats">
            <CardTokenStats />
          </div>
        </div>
      ) : (
        <div className="no_data">
          <h1>{reData?.address}</h1>
          <p>
            Sorry, no data is available at this time. We are actively working to
            bring it to you as soon as possible
          </p>
        </div>
      )}
    </>
  );
}
