export const formatText = (text: string) => {
  let result = text;
  const firstChar = text.charAt(0).toUpperCase();
  if (firstChar !== text.charAt(0)) {
    result = firstChar + text.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2");
  }
  return result;
};

//formating timestamp into "MAY 30, 2023" format for blog posts
export const formatDate = (timeStamp: string) => {
  const inputDate = new Date(timeStamp);
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const month = monthNames[inputDate.getMonth()];
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();

  return `${month} ${day}, ${year}`;
};

//calculation of reading time for blog post
export const calculateReadingTime = (text: string) => {
  // Average reading speed in words per minute
  const averageReadingSpeed = 200;
  // Count the number of words in the text
  const wordCount = text?.split(/\s+/).length;
  // Calculate the reading time in minutes
  const readingTimeInMinutes = Math.ceil(wordCount / averageReadingSpeed);
  return readingTimeInMinutes;
};
