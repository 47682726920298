import React, { useEffect, useState } from "react";
import main_logo from "../../assets/tka_header.png";
// import { FaFacebookF, FaTelegramPlane } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
// import { FaInstagram } from "react-icons/fa";
// import { FaYoutube } from "react-icons/fa";
// import { FaDiscord } from "react-icons/fa";
// import { SiGitbook } from "react-icons/si";
import { Link } from "react-router-dom";

const Footer: any = () => {
  const [currentYr, setCurrentYr] = useState<string>("");

  useEffect(() => {
    const yr = new Date().getFullYear().toString();
    setCurrentYr(yr);
  }, []);

  return (
    <div className="footer">
      <div className="inner-wrapper">
        <div className="row">
          <div className="logo_div">
            <div className="logo">
              <Link to="/" className="link">
                <img
                  src={main_logo}
                  alt="TokenizedAssets_logo"
                  title="TokenizedAssets"
                />
              </Link>
            </div>
            <p>
              Tokenized real world assets data aggregator and analytics
              platform.
            </p>
            <ul>
              <li>
                <a
                  href="https://twitter.com/Tokenized_Asset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="social_icons">
                    <AiOutlineTwitter />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/tokenized-assets/about/?viewAsMember=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="social_icons">
                    <FaLinkedinIn />
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="links_div">
            <div className="links">
              <h5>Links</h5>
              <ul>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
              </ul>
            </div>

            <div className="legal">
              <h5>Legal</h5>
              <ul>
                <li>
                  <Link to="/terms_of_use">Terms of use</Link>
                </li>
                <li>
                  <Link to="/privacy_policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/cookie_policy">Cookie Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="disclaimer">
          <p>
            This site is operated by TokenizedAssets, which is not a registered
            broker-dealer or investment advisor or ATS.
          </p>
          <p>
            TokenizedAssets does not give investment advice, endorsement,
            analysis or recommendations with respect to any securities.
          </p>
          <p>
            Offers to sell, or the solicitations of offers to buy, any security
            can only be made through official offering documents.
          </p>
          <p>
            Investors should conduct their own due diligence, not rely on the
            financial assumptions or estimates displayed on this website, and
            are encouraged to consult
          </p>
          <p>
            with the financial advisor, attorney, accountant, and any other
            professional that can help understand and assess the risks
            associated with any investment opportunity.
          </p>
          <p>
            TokenizedAssets, does not guarantee any investment performance,
            outcome or return of capital for any investment opportunity posted
            on this site.
          </p>
          <p>
            By accessing this site and any pages thereof, you agree to be bound
            by the Terms of Service and Privacy Policy.
          </p>
        </div>
        <div className="footer_bottom">
          <div className="footer_bottom_inner">
            <p>
              TokenizedAssets © {currentYr}.&nbsp; &nbsp;All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

//footer
