import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineCalendar } from "react-icons/ai";
import { FaTemperatureHigh } from "react-icons/fa";
import { BsSnow } from "react-icons/bs";
import { RiParkingBoxLine } from "react-icons/ri";
import { RiRuler2Line } from "react-icons/ri";
import {
  FaTwitterSquare,
  FaTelegram,
  FaDiscord,
  FaFacebookF,
  FaInstagramSquare,
  FaRedditSquare,
  FaGithubSquare,
} from "react-icons/fa";

// export const HomeIcon = () => <AiOutlineHome />;

export const getIconForText = (text: string) => {
  switch (text) {
    case "propertyType":
      return <AiOutlineHome size={20} color="#1A90FF" />;
    case "builtYear":
      return <AiOutlineCalendar size={20} color="#1A90FF" />;
    case "heating":
      return <FaTemperatureHigh size={20} color="#1A90FF" />;
    case "cooling":
      return <BsSnow size={20} color="#1A90FF" />;
    case "parking":
      return <RiParkingBoxLine size={20} color="#1A90FF" />;
    case "LotSize":
      return <RiRuler2Line size={20} color="#1A90FF" />;
    case "twitter":
      return <FaTwitterSquare size={20} color="#1A90FF" />;
    case "telegram":
      return <FaTelegram size={20} color="#1A90FF" />;
    case "discord":
      return <FaDiscord size={20} color="#1A90FF" />;
    case "facebook":
      return <FaFacebookF size={20} color="#1A90FF" />;
    case "instagram":
      return <FaInstagramSquare size={20} color="#1A90FF" />;
    case "reddit":
      return <FaRedditSquare size={20} color="#1A90FF" />;
    case "github":
      return <FaGithubSquare size={20} color="#1A90FF" />;
    default:
      return null;
  }
};
