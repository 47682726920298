import { configureStore } from "@reduxjs/toolkit";
import companyReducer from "./company/companySlice";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  PERSIST,
  PURGE,
  REGISTER,
  PAUSE,
  REHYDRATE,
  persistStore,
} from "redux-persist";

import reGridSlice from "./slices/reGridSlice";
import blogsSlice from "./slices/blogsSlice";

const persistConfig = { key: "root", storage };
const persistedReducer = persistReducer(persistConfig, companyReducer);

export const store = configureStore({
  reducer: {
    company: persistedReducer,
    reApiData: reGridSlice,
    blogsData: blogsSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
