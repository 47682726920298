import { Fragment, useState } from "react"
import { NavLink } from "react-router-dom"
export default function Sidebar({ isOpen, closeSidebar }: any): JSX.Element {
  const styles = isOpen ? "sidebar sidebar--open" : "sidebar sidebar--closed"
  const [loader, setLoader] = useState(false)

  return (
    <Fragment>
      {isOpen ? (
        <div className="sidebar__backdrop" onClick={closeSidebar}></div>
      ) : (
        <Fragment></Fragment>
      )}

      <nav className={`${styles}`}>
        <div className="container">
          <ul className="container__list">
            <li className="container__list__item" onClick={closeSidebar}>
              <NavLink className="sidebar__link" to={`/`}>
                Marketplace
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </Fragment>
  )
}
