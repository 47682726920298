import { Modal, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import ImageCarousel from "../ui/imgCarousel/ImageCarousel";
import ReactGA from "react-ga4";

import MapComponent from "../ui/map/MapComponent";
import { useListDigitalRealEstate } from "src/hooks/useListDigitalRealEstate";
import { formatText } from "src/utils/commonUtils";
import { getIconForText } from "src/utils/icons/Icons";

const BasicDetails = ({ basicInfo }: any) => {
  return (
    <div className="basic_details">
      <h3>Basic Details</h3>
      <div className="details_row">
        {Object.entries(basicInfo).map(
          ([key, value]) =>
            // Add if statement to conditionally render row_item div
            value !== null && (
              <div className="row_item" key={key}>
                {getIconForText(key)}
                <span>{value}</span>
              </div>
            )
        )}
      </div>
    </div>
  );
};
const OtherDetails = ({ title, data }: any) => {
  return (
    <div className="details_column">
      <h3>{title}</h3>
      {data && (
        <ul>
          {Object.entries(data).map(([key, value]) => (
            <li key={key}>
              {formatText(key)} : {value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const TraditionalDataTab = (props: any) => {
  const { reId, reData } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showFull, setShowFull] = useState(false);

  //google analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/analytics/traditionalDataTab",
      title: "TraditionalDataTab",
    });
  }, []);

  const { getTraditionalInfo, traditionalData, traditionalDataLoading } =
    useListDigitalRealEstate();
  useEffect(() => {
    getTraditionalInfo({
      variables: { reId, companyName: reData.company },
    });
  }, []);

  const toggleShowFull = () => {
    setShowFull(!showFull);
  };
  if (traditionalDataLoading) {
    return (
      <div className="TraditionalTab_loader">
        <div className="loadingio-spinner-ripple-67ewwazwocp">
          <div className="ldio-dg66tpfuqso">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="TraditionalTab_wrapper">
      {traditionalData?.reId ? (
        <>
          <h2 className="title">{reData?.address}</h2>
          <BasicDetails basicInfo={traditionalData?.basic} />
          {traditionalData?.reId && (
            <div className="other_details">
              <OtherDetails
                title="Interior details"
                idx={0}
                data={traditionalData?.interiorDetails}
              />
              <OtherDetails
                title="Property details"
                data={traditionalData?.propertyDetails}
              />
              <OtherDetails
                title="Construction details"
                data={traditionalData?.constructionDetails}
              />
              <OtherDetails
                title="Utilities"
                data={traditionalData?.utilities}
              />
            </div>
          )}
          {/* <div className="toggle_btn_div">
          <Button sx={{ width: "100%" }} variant="outlined">
            Show more Details
          </Button>
        </div> */}
          <div className="map_img_wrapper">
            <div className="map_wrapper" onClick={handleOpen}>
              <MapComponent
                reAddress={reData?.address}
                coordinates={[
                  traditionalData?.latitude,
                  traditionalData?.longitude,
                ]}
              />
            </div>
            <div className="img_wrapper">
              {traditionalData?.images && (
                <ImageCarousel images={traditionalData?.images} />
              )}
            </div>
          </div>
          {traditionalData?.about && (
            <div className="about_div">
              <h3>About</h3>
              <p className="para-sm">
                {showFull
                  ? traditionalData?.about
                  : `${traditionalData?.about.slice(0, 240)} ...`}{" "}
                <span onClick={toggleShowFull}>
                  {showFull ? "Show less" : "Show more"}
                </span>
              </p>
              <p className="para-lg">{traditionalData?.about}</p>
            </div>
          )}
        </>
      ) : (
        <div className="no_data">
          <h1>{reData?.address}</h1>
          <p>
            Sorry, no data is available at this time. We are actively working to
            bring it to you as soon as possible
          </p>
        </div>
      )}
    </div>
  );
};

export default TraditionalDataTab;
