export const TermsOfUse = () => {
  return (
    <div className="page_wrapper">
      <h1>Terms of use</h1>
      <p>
        PLEASE READ THESE TERMS AND CONDITIONS OF USE (SITE TERMS) CAREFULLY.
        THESE SITE TERMS AND OTHER AGREEMENTS ON THE SITES GOVERN YOUR USE OF
        OUR WEB SITE OR MOBILE APPLICATION (THE SITES), AND ALSO INCLUDE
        IMPORTANT DISCLOSURES AND REGULATORY INFORMATION THAT ARE ASSOCIATED
        WITH THE SERVICES AVAILABLE ON THE SITES, ALL OF WHICH ARE IMPORTANT AND
        AFFECT YOUR LEGAL RIGHTS, SO PLEASE READ THEM CAREFULLY.
      </p>
      <p>
        BY ACCESSING OR USING THE SITES, YOU REPRESENT THAT (A) YOU HAVE READ,
        UNDERSTAND, AND AGREE TO BE BOUND BY THE SITE TERMS, (B) YOU ARE OF
        LEGAL AGE TO FORM A BINDING CONTRACT WITH TokenizedAssets, AND (C) YOU
        HAVE THE AUTHORITY TO ENTER INTO THE TERMS OF USE PERSONALLY OR ON
        BEHALF OF THE ENTITY YOU HAVE NAMED AS THE REGISTERED USER, AND TO BIND
        THAT ENTITY TO THE SITE TERMS. THE TERM YOU REFERS TO THE INDIVIDUAL OR
        LEGAL ENTITY, AS APPLICABLE, IDENTIFIED AS THE REGISTERED USER WHEN YOU
        SUBMIT YOUR REGISTRATION INFORMATION ON THE SITES. YOU AGREE TO BE BOUND
        BY THE SITE TERMS DESCRIBED HEREIN AND ALL TERMS INCORPORATED BY
        REFERENCE. YOUR ACCESS TO AND USE OF THE SITES CONSTITUTES YOUR
        ACCEPTANCE OF AND AGREEMENT TO ABIDE BY EACH OF THE SITE TERMS SET FORTH
        BELOW. IF YOU DO NOT AGREE TO ALL OF THESE SITE TERMS, DO NOT USE OUR
        WEB SITE OR MOBILE APPLICATION
      </p>
      <p>
        TokenizedAssets reserves the right to change or modify any of these Site
        Terms or any policy or guideline of the Sites, at any time and in its
        sole discretion. If TokenizedAssets makes changes to these Site Terms,
        we will provide notice of such changes, such as by posting a notice on
        the Sites or updating the “Last Updated” date, which may be listed on
        the Sites. Your continued use of our Sites following the posting of
        changes or modifications will confirm your acceptance of such changes or
        modifications. Therefore, you should frequently review the Site Terms
        and applicable policies whenever you access the Sites and at least every
        thirty (30) days to make sure that you understand the terms and
        conditions that will apply to you and your use of the Sites. If you do
        not agree to the amended terms, you must stop using the Sites.
      </p>
      <div className="sections">
        <h3>Services; Securities Offerings</h3>
        <p>
          Among the Services, this Sites present information regarding potential
          investments in limited liability company membership interests
          (Interests) of various series of TokenizedAssets (each, a Series).
          These Series Interests, which are securities under U.S. securities
          laws, will be represented by cryptographic digital tokens/NFTs
          (TokenizedAssets), which are a new series of Ethereum virtual machine
          compatible blockchain-based smart contract digital tokens meeting the
          ERC-20 and ERC1155 standard as modified to meet transfer restriction
          requirements under applicable U.S. securities laws.
        </p>
        <p>
          By participating in a TokenizedAssets Series offering through the
          Sites you agree and understand that each TokenizedAssets Series
          offering has its own terms, rules and risks and it is your
          responsibility to carefully review all terms, rules, risk factors and
          Series offering documents and decide on your own if you agree to them.
        </p>
        <p>
          TokenizedAssets will allow you the opportunity to designate investment
          opportunities in which you would like to participate. However, your
          decision to participate in a Series offering of TokenizedAssets does
          not mean that you will be permitted to participate in such offering.
          Our decisions to permit your participation are subject to, among other
          things, demonstration to our satisfaction that you meet legal
          requirements for participation, consent of relevant third parties,
          execution of definitive documentation, and our discretion. We make no
          representation or warranty that the potential transaction you have
          identified will take place, or that if it takes place that you will be
          allowed to participate. If you are offered the opportunity to
          participate in a transaction, you will be required to sign additional
          documentation. TokenizedAssets management decisions are final in all
          matters relating to the Sites, Services, investments, and other
          activities related to the Sites.
        </p>
        <p>
          The securities associated with any Series offering of TokenizedAssets
          in which you participate will be governed by the terms and conditions
          set forth in the applicable investment documents related to the
          specific Series offering provided to you. You may need to complete
          several steps to purchase TokenizedAssets in a specific Series
          offering including, but not limited to, (i) completing TokenizedAssets
          user registration process, which includes you providing identification
          and qualification information to TokenizedAssets, (ii) entering into
          Series offering agreements with the specific Series, (iii) funding one
          or more investments in fiat currency or a specified cryptocurrency,
          (iv) confirming an intention to move forward with participation in the
          Series offering at the closing, if required by the Series; (v)
          creating a digital asset wallet with a third party, and (vii)
          providing other information to TokenizedAssets or the Series Manager
          or taking other action as requested throughout the Series offering
          process.
        </p>
        <p>
          The Series TokenizedAssets offered on the Sites have not been
          registered under the Securities Act of 1933, as amended (the
          Securities Act) in reliance on the exempted provisions of Section
          4(a)(2) of the Securities Act and Rule 506 of Regulation D promulgated
          thereunder, and/or Regulation S. Securities sold through private
          placements are restricted and not publicly traded and are therefore
          illiquid. Neither the U.S. Securities and Exchange Commission nor any
          state securities commission or other regulatory authority has
          approved, passed upon or endorsed the merits of any securities
          offering on these Sites.
        </p>
        <p>
          The Series TokenizedAssets being offered are highly speculative in
          nature, involve a high degree of risk and should be purchased only by
          persons who can afford to lose their entire investment. There can be
          no assurance that TokenizedAssets business objectives will be achieved
          or that a secondary market will ever develop for the Interests,
          whether via the TokenizedAssets Website, via third party registered
          broker-dealers or otherwise. Investing in private placements requires
          high risk tolerance, low liquidity concerns, and long-term
          commitments. The Interests being sold are not FDIC insured, may lose
          value, and there is no bank or other guarantee
        </p>
      </div>
      <div className="sections">
        <h3>Legality</h3>
        <p>
          Use of the Sites in violation of applicable laws is expressly
          prohibited. You are solely responsible for verifying that your use of
          the Sites and consummation of transactions contemplated by the Sites
          are permitted in your jurisdiction and comply with all applicable
          laws, regulations, orders and court decisions. If you have any
          questions regarding legality in your jurisdiction, please consult your
          legal counsel. When you access, register for or use the Sites, you do
          so at your own risk, and you are solely responsible for complying with
          all applicable rules and regulations in connection with activities
          relating to the Sites.
        </p>
      </div>
      <div className="sections">
        <h3>No Financial Advice</h3>
        <p>
          TokenizedAssets is not a registered broker-dealer, funding portal, or
          investment advisor and does not conduct any activity that would
          require such registration. None of the information contained on the
          Sites constitutes a recommendation by TokenizedAssets or its
          affiliates to buy or sell any securities or other assets. The
          information contained in the Sites has been prepared without reference
          to any particular user’s investment requirements or financial
          situation. The Sites do not offer financial advice or recommend
          investments. We recommend that you consult with qualified
          professionals prior to making actual investments or financial
          decisions. Any information available through the Sites is not offered
          as financial advice and should not be the basis for actual financial
          activities. We are not liable for any reliance on information
          available through the Sites by you or anyone who you may inform of its
          contents.
        </p>
      </div>
      <div className="sections">
        <h3>
          Registration Data; Account Security; Privacy Policy; Digital Asset
          Wallet
        </h3>
        <p>
          In order to use the Sites and the Services, you will be required to
          create a service account (Account) by registering on the
          TokenizedAssets website to become a registered user of the Sites and
          the Services. To register, you must provide your real name, address,
          phone number and e-mail address, along with other information that may
          be required from time to time. The right to use the Sites and the
          Services is personal to you as the user or to the business you are
          registering for and is not transferable to any other person. As a user
          of our Sites and Services, you must comply with these Site Terms and
          our Privacy policies.
        </p>
        <p>
          In consideration of your use of the Sites and the Services and by
          creating an Account, you agree to (a) provide accurate, current and
          complete information about you as may be prompted by any registration
          forms on the Site (“Registration Data“); (b) maintain the security of
          your password and identification; (c) maintain and promptly update the
          Registration Data, and any other information you provide to
          TokenizedAssets, to keep it accurate, current and complete; and (d)
          accept all risks of unauthorized access to the Registration Data and
          any other information you provide to TokenizedAssets.
        </p>
        <p>
          By creating an Account, you represent and warrant that all
          Registration Data for the Account that you submit is truthful and
          accurate. You agree not to use the Account of another user. You are
          solely responsible for any and all use of your Account. You agree that
          sharing the Services with another person or providing another person
          access to the Services through your Account breaches these Site Terms
          and may constitute fraud or theft, for which we reserve all rights and
          remedies. You agree not to authorize any other individual or entity
          other than your authorized agents, if any, to use the Sites or
          Services via your Account.
        </p>

        <p>
          You are not obligated under law to provide us with any personally
          identifying information. However, if you do not provide the
          Registration Data or any other requested personally identifying
          information, you will not be able to use certain features of the Sites
          or the Services. TokenizedAssets will use your personally identifying
          information as set forth in the TokenizedAssets Privacy Policy . You
          represent and warrant that you have reviewed the Privacy Policy and
          agree to its terms.
        </p>
        <p>
          Prior to making a TokenizedAssets purchase through the Services, you
          will be prompted to provide information relating to your
          cryptocurrency/digital asset wallet (Wallet). If you do not have a
          Wallet, you will need to purchase a cold storage Wallet or set up an
          account with a cryptocurrency wallet provider chosen by you (Wallet
          Provider). Your use of the Wallet will be subject to the terms of use
          agreement between you and such Wallet Provider.
        </p>
      </div>
      <div className="sections">
        <h3>Copyright and Limited License</h3>
        <p>
          Unless otherwise indicated in the Sites, the Sites and all content and
          other materials on the Sites, including, without limitation, the
          TokenizedAssets logo, and all designs, text, graphics, pictures,
          information, data, software, sound files, other files and the
          selection and arrangement thereof (collectively, the “Site Materials“)
          are the proprietary property of TokenizedAssets or our licensors or
          users and are protected by U.S. and international copyright laws.
        </p>
        <p>
          You are granted a limited, non-sublicensable license to access and use
          the Sites and electronically copy, (except where prohibited without a
          license) and print to hard copy portions of the Site Materials for
          your informational, non-commercial and personal use only. Such license
          is subject to these Site Terms and does not include: (a) any resale or
          commercial use of the Sites or the Site Materials therein; (b) the
          distribution, public performance or public display of any Site
          Materials; (c) modifying or otherwise making any derivative uses of
          the Sites and the Site Materials, or any portion thereof; (d) use of
          any data mining, robots or similar data gathering or extraction
          methods; (e) downloading (other than the page caching) of any portion
          of the Sites, the Site Materials or any information contained therein,
          except as expressly permitted on the Sites; or (f) any use of the
          Sites or the Site Materials other than for its intended purpose.
        </p>

        <p>
          Any use of the Sites or the Site Materials other than as specifically
          authorized herein, without the prior written permission of
          TokenizedAssets, is strictly prohibited and will terminate the license
          granted herein. Such unauthorized use may also violate applicable laws
          including without limitation copyright and trademark laws and
          applicable communications regulations and statutes. Unless explicitly
          stated herein, nothing in these Site Terms shall be construed as
          conferring any license to intellectual property rights, whether by
          estoppel, implication or otherwise. This license is revocable at any
          time.
        </p>
      </div>
      <div className="sections">
        <h3>Repeat Infringer Policy</h3>
        <p>
          In accordance with the Digital Millennium Copyright Act (“DMCA”) and
          other applicable law, TokenizedAssets has adopted a policy of
          terminating access to the Sites, in appropriate circumstances and at
          TokenizedAssets sole discretion, to subscribers or account holders, if
          any are allowed, who are deemed to be repeat infringers.
          TokenizedAssets may also at its sole discretion limit access to the
          Sites and/or terminate the accounts of any users who infringe any
          intellectual property rights of others, whether or not there is any
          repeat infringement.
        </p>
      </div>
      <div>
        <h3>Copyright Complaints</h3>
        <p>
          If you believe that anything on the Sites infringes upon any copyright
          which you own or control you may file a notification of such
          infringement with our Designated Agent as set forth below.
          <p>
            Name of Agent Designated to Receive Notification of Claimed
            Infringement: ___________________
          </p>
          <p>
            Full Address of Designated Agent to Which Notification Should be
            Sent:___________________
          </p>
          <p>Telephone Number of Designated Agent: ___________________</p>
          <p>Facsimile Number of Designated Agent: ____________________</p>
          <p>E-Mail Address of Designated Agent: ________</p>
          <p>
            Please see 17 U.S.C. 512(c)(3) for the requirements of a proper
            notification. You should note that if you knowingly misrepresent in
            your notification that the material or activity is infringing, you
            will be liable for any damages, including costs and attorneys’ fees,
            incurred by TokenizedAssets or the alleged infringer as the result
            of our relying upon such misrepresentation in removing or disabling
            access to the material or activity claimed to be infringing.
          </p>
          <p>
            Name of Designated Agent: Siddu Kadi Full Address of Designated
            Agent to Which Notification should be sent: 4910 E Adamo Dr, Unit B
            Tampa, FL 33432 Telephone number of Designated Agent: (614) 530 –
            8777 Email Address of Designated Agent: siddu@3Blocks.io
          </p>
        </p>
      </div>
      <div className="sections">
        <h3>Trademarks</h3>
        <p>
          TokenizedAssets, the TokenizedAssets logo and any other product or
          service name or slogan contained in the Sites are trademarks of
          TokenizedAssets and its suppliers or licensors, and may not be copied,
          imitated or used, in whole or in part, without the prior written
          permission of TokenizedAssets or the applicable trademark holder. You
          may not use any metatags or any other “hidden text” utilizing
          TokenizedAssets or any other name, trademark or product or service
          name of TokenizedAssets without our prior written permission. In
          addition, the look and feel of the Sites, including all page headers,
          custom graphics, button icons and scripts, is the service mark,
          trademark and/or trade dress of TokenizedAssets and may not be copied,
          imitated, or used, in whole or in part, without our prior written
          permission. All other trademarks, registered trademarks, product names
          and company names or logos mentioned in the Sites are the property of
          their respective owners. Reference to any products, services,
          processes, or other information, by trade name, trademark,
          manufacturer, supplier or otherwise does not constitute or imply
          endorsement, sponsorship, or recommendation thereof by
          TokenizedAssets.
        </p>
      </div>
      <div className="sections">
        <h3>Hyperlinks</h3>
        <p>
          You are granted a limited, non-exclusive right to create a text
          hyperlink to the Sites for noncommercial purposes, provided such link
          does not portray TokenizedAssets or any of its products and services
          in a false, misleading, derogatory, or otherwise defamatory manner and
          provided further that the linking site does not contain any adult or
          illegal material or any material that is offensive, harassing or
          otherwise objectionable. This limited right may be revoked at any
          time. You may not use a TokenizedAssets logo or other proprietary
          graphic of TokenizedAssets to link to this Sites without the express
          written permission of TokenizedAssets. Further, you may not use,
          frame, or utilize framing techniques to enclose any TokenizedAssets
          trademark, logo, or other proprietary information, including the
          images found at the Sites, the content of any text or the
          layout/design of any page or form contained on a page on the Sites
          without TokenizedAssets express written consent. Except as noted
          above, you are not conveyed any right or license by implication,
          estoppel or otherwise in or under any patent, trademark, copyright, or
          proprietary right of TokenizedAssets or any third party.
        </p>
        <p>
          TokenizedAssets makes no claim or representation regarding, and
          accepts no responsibility for, the quality, content, nature, or
          reliability of third-party Web sites accessible by hyperlink from the
          Sites, or Web sites linking to the Sites. Such sites are not under the
          control of TokenizedAssets and TokenizedAssets is not responsible for
          the contents of any linked site or any link contained in a linked
          site, or any review, changes, or updates to such sites.
          TokenizedAssets provides these links to you only as a convenience, and
          the inclusion of any link does not imply affiliation, endorsement, or
          adoption by TokenizedAssets of any site or any information contained
          therein. When you leave the Sites, you should be aware that our terms
          and policies no longer govern. You should review the applicable terms
          and policies, including privacy and data gathering practices, of any
          site to which you navigate from the Sites.
        </p>
      </div>
      <div className="sections">
        <h3>Third-Party Content </h3>
        <p>
          TokenizedAssets may provide third party content on the Sites and may
          provide links to Web pages and content of third parties (collectively,
          “Third-Party Content”) as a service to those interested in this
          information. TokenizedAssets does not control, endorse, or adopt any
          Third-Party Content and makes no representation or warranties of any
          kind regarding the Third-Party Content, including without limitation
          regarding its accuracy or completeness. You acknowledge and agree that
          TokenizedAssets is not responsible or liable in any manner for any
          Third-Party Content and undertakes no responsibility to update or
          review any Third-Party Content. Users use such Third-Party Content
          contained therein at their own risk.
        </p>

        <h4>1.Third-Party Products and Services</h4>
        <p>
          TokenizedAssets may provide or allow users to provide information
          about or links to third-party products or services on the Sites. Your
          business dealings or correspondence with, or participation in
          promotions of, such third parties, and any terms, conditions,
          warranties, or representations associated with such dealings or
          promotions, are solely between you and such third party.
          TokenizedAssets is not responsible or liable for any loss or damage of
          any sort incurred as the result of any such dealings or promotions or
          as the result of the presence of such non-TokenizedAssets advertisers
          or third-party information on the Sites.
        </p>
      </div>
      <div className="sections">
        <h3>Feedback</h3>
        <p>
          You acknowledge and agree that any questions, comments, suggestions,
          feedback, ideas, plans, notes, drawings, original or creative
          materials or other information or materials regarding the Sites,
          TokenizedAssets or TokenizedAssets general products or services (the
          “Feedback”) that are provided by you in the form of email or other
          submissions to TokenizedAssets, or any postings on the Sites, are (as
          between you and TokenizedAssets) non-confidential and shall become the
          sole property of TokenizedAssets. TokenizedAssets shall own, and you
          hereby assign to TokenizedAssets, all exclusive rights, including all
          intellectual property rights, and shall be entitled to the
          unrestricted use and dissemination of such Feedback for any purpose,
          commercial or otherwise, without acknowledgment or compensation to
          you.
        </p>
      </div>
      <div className="sections">
        <h3>User Content and Interactive Services or Areas</h3>
        <p>
          The Sites may include interactive areas or services (“Interactive
          Areas”), such as forums, chat rooms or message boards, online hosting
          or storage services, or other areas or services in which you or other
          users create, post, or store any content, messages, materials, data,
          information, text, music, sound, photos, video, graphics,
          applications, code or other items or materials on the Sites (“User
          Content”). You are solely responsible for your use of such Interactive
          Areas and use them at your own risk. By using any Interactive Areas,
          you agree not to upload to, post, transmit, store, distribute, create,
          or otherwise publish through the Sites any of the following:
        </p>
        <ul>
          <li>
            User Content that would constitute, encourage or provide
            instructions for a criminal offense, violate the rights of any
            party, or that would otherwise create liability or violate any
            local, state, national or international law, including, without
            limitation, the regulations of the U.S. Securities and Exchange
            Commission or any rules of a securities exchange such as the New
            York Stock Exchange, the American Stock Exchange or the NASDAQ;
          </li>
          <li>
            User Content that is unlawful, libelous, defamatory, pornographic,
            obscene, lewd, indecent, suggestive, harassing, threatening,
            invasive of privacy or publicity rights, abusive, fraudulent,
            inflammatory, or otherwise objectionable; User Content that
            impersonates any person or entity or otherwise misrepresents your
            affiliation with a person or entity;
          </li>
          <li>
            User Content that may infringe any patent, trademark, trade secret,
            copyright or other intellectual or proprietary right of any party.
            By posting any User Content, you represent and warrant that you have
            the lawful right to distribute and reproduce such User Content;
          </li>
          <li>
            Unsolicited promotions, political campaigning, advertising or
            solicitations;
          </li>
          <li>
            Viruses, corrupted data or other harmful, disruptive or destructive
            files;
          </li>
          <li>
            Private information of any third party, including, without
            limitation, addresses, phone numbers, email addresses, Social
            Security numbers and credit card numbers; and User Content that, in
            the sole judgment of TokenizedAssets, is objectionable or which
            restricts or inhibits any other person from using or enjoying the
            Interactive Areas or the Sites, or which may expose TokenizedAssets
            or its users to any harm or liability of any type.
          </li>
          <li>
            You further agree that you are solely responsible for your conduct
            while on the Sites, and you agree that you will not do any of the
            following in connection with the Site or its users:
            <ul>
              <li>
                Impersonate or post on behalf or any person or entity or
                otherwise misrepresent your affiliation with a person or entity.
              </li>

              <li>
                Use the Site or the Service in any manner that could interfere
                with, disrupt, negatively affect, or inhibit other users from
                fully enjoying the Site or that could damage, disable,
                overburden, or impair the functioning of the Site in any manner.
              </li>
              <li>
                Stalk, intimidate, threaten, or otherwise harass or cause
                discomfort to other users.
              </li>
              <li>
                Cheat or utilize unauthorized exploits in connection with the
                Service.
              </li>
              <li>Send any unsolicited commercial messages.</li>
              <li>
                Use the Site or the Service for any illegal or unauthorized
                purpose or engage in, encourage, or promote any illegal
                activity, or any activity that violates these Terms of Use; or
                Circumvent or attempt to circumvent any filtering, security
                measures or other features TokenizedAssets may from time to time
                adopt to protect the Sites, its users or third parties.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          TokenizedAssets takes no responsibility and assumes no liability for
          any User Content posted, stored, or uploaded by you or any third
          party, or for any loss or damage thereto, nor is TokenizedAssets
          liable for any mistakes, defamation, slander, libel, omissions,
          falsehoods, obscenity, pornography or profanity you may encounter.
          Your use of Interactive Areas is at your own risk. Enforcement of the
          user content or conduct rules set forth in these Site Terms is solely
          at TokenizedAssets discretion, and failure to enforce such rules in
          some instances does not constitute a waiver of our right to enforce
          such rules in other instances. In addition, these rules do not create
          any private right of action on the part of any third party or any
          reasonable expectation that the Sites will not contain any content
          that is prohibited by such rules. As a provider of interactive
          services, TokenizedAssets is not liable for any statements,
          representations or User Content provided by its users in any public
          forum, personal home page or other Interactive Area. Although
          TokenizedAssets has no obligation to screen, edit or monitor any of
          the Content posted in any Interactive Area, TokenizedAssets reserves
          the right, and has absolute discretion, to remove, screen or edit any
          User Content posted or stored on the Sites at any time and for any
          reason without notice, and you are solely responsible for creating
          backup copies of and replacing any User Content you post or store on
          the Sites at your sole cost and expense. Any use of the Interactive
          Areas or other portions of the Sites in violation of the foregoing
          violates these Site Terms and may result in, among other things,
          termination, or suspension of your rights to use the Interactive Areas
          and/or the Sites.
        </p>
        <p>
          If you post User Content to the Sites, unless we indicate otherwise,
          you grant TokenizedAssets and its affiliates a nonexclusive,
          royalty-free, perpetual, irrevocable and fully sublicensable right to
          use, reproduce, modify, adapt, publish, translate, create derivative
          works from, distribute, perform and display such User Content
          throughout the world in any media on or in connection with the Sites
          and the promotion thereof, including without limitation the right to
          use your name, likeness, voice or identity. You grant TokenizedAssets
          and its affiliates and sublicensees the right to use the name that you
          submit in connection with such content if we choose. You understand
          and agree that the use of your or other users name, likeness, voice,
          or identity in connection with various features on the Sites does not
          imply any endorsement of such feature or of the Sites of
          TokenizedAssets unless explicitly stated otherwise. You represent and
          warrant that (a) you own and control all of the rights to the User
          Content that you post or you otherwise have the right to post such
          User Content to the Sites; (b) the User Content is accurate and not
          misleading; and (c) use and posting of the User Content you supply
          does not violate these Site Terms and will not violate any rights of
          or cause injury to any person or entity.
        </p>
      </div>
      <div className="sections">
        <h3>Indemnification</h3>
        <p>
          You agree to defend, indemnify and hold harmless TokenizedAssets, its
          affiliates, its independent contractors, service providers and
          consultants, and their respective directors, employees and agents,
          from and against any claims, damages, costs, liabilities and expenses
          (including, but not limited to, reasonable attorneys’ fees) arising
          out of or related to any User Content you post, store or otherwise
          transmit on or through the Sites or your use of the Interactive Areas,
          including without limitation any actual or threatened suit, demand or
          claim made against TokenizedAssets and/or its affiliates, independent
          contractors, service providers, employees, directors or consultants,
          arising out of or relating to the User Content, your conduct, your
          violation of these Site Terms or your violation of the rights of any
          third party.
        </p>
      </div>
      <div className="sections">
        <h3>Disclaimer</h3>
        <p>
          Except as expressly provided to the contrary in a writing by
          TokenizedAssets, the sites, the site materials contained therein and
          the services provided on or in connection therewith (the “services”)
          are provided on an “as is” basis without warranties of any kind,
          either express or implied. TokenizedAssets disclaims all other
          warranties, express or implied, including, without limitation, implied
          warranties of merchantability, fitness for a particular purpose,
          title, and non-infringement as to the sites and the services,
          including the information, content and materials contained therein.
          TokenizedAssets does not represent or warrant those materials in the
          sites or the services are accurate, complete, reliable, current or
          error-free. TokenizedAssets does not represent or warrant that the
          sites or its servers are free of viruses or other harmful components.
        </p>
        <p>
          TokenizedAssets is not responsible for typographical errors or
          omissions relating to pricing, text, or photography. While
          TokenizedAssets attempts to make your access and use of the sites and
          the services safe, TokenizedAssets cannot and does not represent or
          warrant that the sites or its server(s) are free of viruses or other
          harmful components; therefore, you should use industry-recognized
          software to detect and disinfect viruses from any download.
        </p>
      </div>
      <div className="sections">
        <h3>Limitation of Liability</h3>
        <p>
          In no event shall TokenizedAssets, its directors, members, employees
          or agents be liable for any direct, special, indirect or consequential
          damages, or any other damages of any kind, including but not limited
          to loss of use, loss of profits or loss of data, whether in an action
          in contract, tort (including but not limited to negligence) or
          otherwise, arising out of or in any way connected with the use of or
          inability to use the Sites, the Services, the content or the materials
          contained in or accessed through the Sites, including without
          limitation any damages caused by or resulting from reliance by user on
          any information obtained from TokenizedAssets, or that result from
          mistakes, omissions, interruptions, deletion of files or email,
          errors, defects, viruses, delays in operation or transmission or any
          failure of performance, whether or not resulting from acts of god,
          communications failure, theft, destruction or unauthorized access to
          TokenizedAssets records, programs or services. In no event shall the
          aggregate liability of TokenizedAssets, whether in contract, warranty,
          tort (including negligence, whether active, passive or imputed),
          product liability, strict liability or other theory, arising out of or
          relating to the use of or inability to use the sites or the services
          or to these site terms exceed any compensation you pay, if any, to
          TokenizedAssets for access to or use of the sites.
        </p>
        <p>
          There are risks associated with using an internet based blockchain
          protocol (including the TokenizedAssets we may create for you
          implementing a customized ERC-20/ERC1155 standards), including but not
          limited to, risks relating to hardware, software and internet
          connections, the risk of malicious software introduction, and the risk
          that third parties may obtain unauthorized access to information
          stored within your Account or the digital assets stored in your
          Wallet. You accept and acknowledge that TokenizedAssets will not be
          responsible for any communication failures, disruptions, errors,
          distortions, delays, or third-party fraud you may experience caused by
          circumstances beyond our reasonable control.
        </p>
        <p>
          TokenizedAssets WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS
          AND TAKES NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR,
          ANY DAMAGES ARISING FROM ANY DECISIONS OR ACTIONS MADE BY YOU BASED ON
          TokenizedAssets SITES CONTENT OR INFORMATION ON TokenizedAssets SERIES
          OFFERINGS OR THROUGH USE OF CRYPTOCURRENCY OR TokenizedAssets
          INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING
          FROM: (A) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY
          CONSTRUCTED TRANSACTIONS, INCORRECT CRYPTOCURRENCY WALLET ADDRESSES,
          BANK WIRE INFORMATION OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR
          DATA LOSS, (C) CORRUPTED WALLET FILES, (D) UNAUTHORIZED ACCESS TO
          SITES OR SERVICES, (E) ANY UNAUTHORIZED THIRD PARTY ACTIVITIES,
          INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING,
          BRUTEFORCING OR OTHER MEANS OF CYBER ATTACK AGAINST THE
          TokenizedAssets SITES OR ANY ASSOCIATED SERVICES, INCLUDING THE
          TokenizedAssets.
        </p>
      </div>
      <div className="sections">
        <h3>Modifications to the Sites</h3>
        <p>
          TokenizedAssets reserves the right to change any and all content
          contained in the Sites and to modify, suspend or discontinue the Sites
          or any Services offered through the Sites or any features or
          functionality of the Sites or the Services at any time without notice
          and without obligation or liability to you.
        </p>
      </div>
      <div className="sections">
        <h3>Applicable Law and Venue</h3>
        <p>
          These Site Terms and your use of the Sites shall be governed by and
          construed in accordance with the laws of the State of Florida,
          applicable to agreements made and to be entirely performed within the
          State of Florida, without resort to its conflict of law provisions.
          You agree that any action at law or in equity arising out of or
          relating to these Site Terms shall be filed only in the state and
          federal courts located in Hillsborough County, Florida and you hereby
          irrevocably and unconditionally consent and submit to the exclusive
          jurisdiction of such courts over any suit, action or proceeding
          arising out of these Site Terms.
        </p>
      </div>
      <div className="sections">
        <h3>Termination</h3>
        <p>
          Notwithstanding any of these Site Terms, TokenizedAssets reserves the
          right, without notice and in its sole discretion, to terminate your
          license to use the Sites, and to block or prevent future your access
          to and use of the Sites.
        </p>
      </div>
      <div className="sections">
        <h3>Severability</h3>
        <p>
          If any provision of these Site Terms shall be deemed unlawful, void or
          for any reason unenforceable, then that provision shall be deemed
          severable from these Site Terms and shall not affect the validity and
          enforceability of any remaining provisions.
        </p>
      </div>
    </div>
  );
};
