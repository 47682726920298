import Marquee from "react-fast-marquee"
import { BiLinkExternal } from "react-icons/bi"
import bonfire from "../../../assets/bonfire.png"
import aws from "../../../assets/aws.png"
import taxnodes from "../../../assets/taxnodes.svg"
import bluejay from "../../../assets/bluejay.svg"
import ryzer from "../../../assets/ryzer.png"
import crypsense from "../../../assets/crypsense.png"
import zoth from "../../../assets/zoth.png"
import yelli from "../../../assets/yelli.svg"
const testimonials = [
  {
    name: "Sarah H",
    info: "As a small business owner, I appreciate the Tokenized Assets Data Platform for its business-friendly features. It helps me diversify my investments and explore opportunities beyond traditional markets. The platform's analytical insights and customizable filters enable me to make informed investment decisions for the growth of my business.",
  },
  {
    name: "Gustavo U",
    info: "As a young investor with limited capital, the Tokenized Assets Data Platform has opened up a world of opportunities for me. With its user-friendly interface and personalized recommendations, I can explore tokenized assets and make smart investments. It's a valuable tool for young investors looking to grow their wealth.",
  },
  {
    name: "Jasmeeth Singh",
    info: "I have been investing in various asset classes for years, but the Tokenized Assets Data Platform has truly transformed my investment journey. With its comprehensive insights and analytics, I can make informed decisions and diversify my portfolio with ease. It's a game-changer for wealthy investors like me.",
  },
]

const partners = [
  {
    company: "AWS Jumpstart",
    info: "https://aws.amazon.com/sagemaker/jumpstart/?p=pm&c=sm&z=2",
    logo: aws,
  },
  {
    company: "Taxnodes",
    info: "https://www.taxnodes.com/",
    logo: taxnodes,
  },
  {
    company: "Bluejay Finance",
    info: "https://bluejay.finance/",
    logo: bluejay,
  },
  {
    company: "Ryzer",
    info: "https://ryzer.app/",
    logo: ryzer,
  },
  {
    company: "Crypsense Digital Group",
    info: "https://www.crypsense.io/",
    logo: crypsense,
  },

  {
    company: "Bonfire Capital",
    info: "https://www.bonfire.capital/",
    logo: bonfire,
  },
  {
    company: "Zoth",
    info: "http://zoth.io/",
    logo: zoth,
  },
  {
    company: "Yelli",
    info: "http://yelli.io/",
    logo: yelli,
  },
]
const AboutMarquee = () => {
  return (
    <Marquee
      speed={60}
      gradient={false}
      pauseOnHover={true}
      className="marquee"
      autoFill
    >
      {testimonials.map((testimonial) => {
        return (
          <div key={testimonial.name} className="card">
            <div className="carditem">{testimonial.name}</div>
            <div className="carditem -info">{testimonial.info}</div>
          </div>
        )
      })}
    </Marquee>
  )
}

export const PartnershipMarquee = () => {
  return (
    <Marquee
      speed={60}
      gradient={false}
      pauseOnHover={true}
      className="marquee -partner"
      autoFill
    >
      {partners.map((partner) => {
        return (
          <div key={partner.company} className="partnercard">
            <div className="cardlogo">
              <img src={partner.logo} alt={partner.company} />
            </div>

            <div className="carditem -info">
              {partner.info.length > 0 ? (
                <a
                  href={partner.info}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <BiLinkExternal />
                </a>
              ) : null}
            </div>
          </div>
        )
      })}
    </Marquee>
  )
}
export default AboutMarquee
