import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store";

interface CompanyState {
  companyName: string;
}
const initialState: CompanyState = { companyName: "" };

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    updateCompany: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload;
    },
  },
});

export const { updateCompany } = companySlice.actions;

export const selectCompany = (state: RootState) => state.company.companyName;

export default companySlice.reducer;
