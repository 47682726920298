import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(stat: string, figure: number) {
  return { stat, figure };
}

const rows = [
  createData("Token Price", 49.99),
  createData("7d High / Low", 49.99),
  createData("Trading Volume", 10000),
  createData("Market Cap", 253256),
  createData("All-Time High", 49.99),
  createData("All-Time Low", 38.99),
];

export default function CardTokenStats() {
  return (
    <TableContainer
      className="tokenstats"
      component={Paper}
      sx={{ boxShadow: "none" }}
    >
      <Table
        sx={{
          minWidth: 250,
        }}
        aria-label="token stats"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              <h3 style={{ display: "inline" }}>Token Statistics</h3>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.stat}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                {row.stat}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: "bold" }}>
                {row.figure}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
