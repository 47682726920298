import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./style/index.scss";
import App from "./App";
import { persistor, store } from "./state/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import client from "./client";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
