import { MdEmail } from "react-icons/md"
import { BiLinkExternal } from "react-icons/bi"

const ContactForm = () => {
  return (
    <div className="container">
      <div className="box">
        <div className="inner-box">
          <h2 className="title">Contact Us</h2>
          <p className="subtitle">
            Get in touch with our team to learn more about our data platform and
            how it can help you make informed investment decisions.
          </p>
          <div className="button-group">
            <a
              className="contact-button"
              target="_blank"
              href="mailto:queries@tokenizedassets.ai" rel="noreferrer"
            >
              <MdEmail
                className="icon"
                color="rgba(102, 210, 231, 0.779)"
                size="20px"
              />
              queries@tokenizedassets.ai
            </a>
            <BiLinkExternal color="rgba(102, 210, 231, 0.779)" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
